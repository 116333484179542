import { Button, Dialog, DialogActions } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const DeleteItemPopup = ({
  callback, handleClose, open, children, showDeleteButton = true
}) => {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      {children}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        { showDeleteButton && (
        <Button onClick={() => { callback(); }} color="primary">
          Delete
        </Button>
)}
      </DialogActions>
    </Dialog>
  );
};
DeleteItemPopup.propTypes = {
  callback: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default DeleteItemPopup;
