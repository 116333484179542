import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Input from '../../../components/Input';
import ButtonCustom from '../../../components/Button';
import styles from './style';
import api from '../../../instruments/api';
import { ContextApp } from '../../../contextStore/reducer';
import { actions } from '../../../contextStore/actions';
import Toast from '../../../components/Toast';

const CreateUpdateCountry = () => {
  const style = styles();
  const { state, dispatch } = useContext(ContextApp);
  const [isEditing, setEditingToggle] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const [isToastOpened, setToastState] = useState(false);
  const [messageToast, setMessageToast] = useState('Code created successfully!');
  useEffect(() => {
    const { selectedCode } = state;
    if (Object.keys(selectedCode).length) {
      setCode(selectedCode.code);
      setDescription(selectedCode.title);
      setEditingToggle(true);
      setDescriptionError(false);
      setCodeError(false);
    }
  }, [state]);
  const clearState = () => {
    setTimeout(() => {
      dispatch(actions.setCode({}));
      setCode('');
      setDescription('');
      setEditingToggle(false);
    }, 200);
  };

  async function setNewCode() {
    try {
      const response = await api.post('countries', {
        name: code,
      });
      if (response) {
        dispatch(actions.setCreatedCodeToList(response));
        setMessageToast('Country created successfully!');
        setToastState(true);
        clearState();
      }
    } catch (e) {
      // eslint-disable-next-line consistent-return
      return new Error(e);
    }
    return null;
  }

  return (
    <>
      <Box mb={5}>
        <Paper>
          <Box className={style.rootDashboardContainer} p={5}>
            <Typography variant="h6" gutterBottom className={style.dashboardTitle}>
              Add Country
            </Typography>
            <Box className={style.inputContainer}>
              <Typography variant="subtitle1" className={style.inputTypography}>
                Country
              </Typography>
              <Input
                error={codeError}
                value={code}
                onChange={({ target: { value } }) => setCode(value)}
              />
            </Box>
            <Box>
              <ButtonCustom onClick={setNewCode}>Save</ButtonCustom>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Toast
        open={isToastOpened}
        closeToast={() => {
          setToastState(false);
        }}
        message={messageToast}
        type="success"
      />
    </>
  );
};
export default CreateUpdateCountry;
