import React from 'react';
import ProjectsOverview from './ProjectsOverview';
import ProjectsCreatePopup from './CompaniesCreatePopup';

const Projects = (props) => {
  return (
    <>
      <ProjectsOverview {...props} />
      <ProjectsCreatePopup />
    </>
  );
};
export default Projects;
