import React from 'react';
import ReceiptsOverview from './ReceiptsOverview';

const ReceiptsList = (props) => {
  return (
    <>
      <ReceiptsOverview {...props} />
    </>
  );
};

export default ReceiptsList;
