import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/styles';
import styles from './style';
import BudgetData from './BudgetData';
import api from '../../instruments/api';
import FinancingData from './FinancingData';
import Report from './Report';

const StyledTabs = withStyles({
  root: {
    borderRadius: 20,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
})((props) => <Tabs {...props} />);

const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    color: 'rgb(170, 78, 155)  !important',
    '&:focus': {
      opacity: 1,
	    color: '#fff  !important',
	    backgroundColor: 'rgb(170, 78, 155)',
    },
	  '&selected': {
		  color: 'white',
		  backgroundColor: 'rgb(170, 78, 155)',
	  },
  },
}))((props) => <Tab {...props} />);

const Budget = (props) => {
  const { tab, history, match } = props;
  const style = styles();
  const [projectName, setProjectName] = useState('');
  const [projectData, setProjectData] = useState({});
  const handleChange = (event, newValue) => {
	  if (newValue === 0) {
		  history.push(`/projects/budget/${match.params.id}`);
	  }
	  if (newValue === 1) {
		  history.push(`/projects/financing/${match.params.id}`);
	  }
	  if (newValue === 2) {
		  history.push(`/projects/report/${match.params.id}`);
	  }
  };
  useEffect(() => {
	  api.get('projectsDetails', match.params.id).then((res) => {
		  setProjectData(res);
	  });
	  api.getById('projects', match.params.id).then((res) => {
		  setProjectName(res.name || '');
	  });
  }, []);

  function setTotalAmount() {
    if (projectData.total_amount) {
      if (tab === 0) {
        return `€${projectData.total_amount.budget.toFixed(2)}`;
      }
      if (tab === 1) {
        return `€${projectData.total_amount.financing.toFixed(2)}`;
      }
      if (tab === 2) {
	      return '€3.300.00';
      }
      return '';
    }
    return '';
  }

  return (
    <>
      <Box>
        <Paper>
          <Box className={style.rootDashboardContainer} p={5}>
            <Typography variant="h6" gutterBottom className={style.dashboardTitle}>
              {projectName}
              {' '}
              {
	            	props.location.pathname.includes('budget')
			            ? 'Budget Overview'
			            : props.location.pathname.includes('financing')
				            ? 'Financing Overview'
				            : 'Report'
	            }
            </Typography>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper
                className={style.tabs}
                square
              >
                <StyledTabs
                  value={tab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                >
                  <StyledTab label="Budget" style={{ minWidth: '33.3%' }} />
                  <StyledTab label="Financing" style={{ minWidth: '33.3%', borderLeft: '3px solid white' }} />
                  <StyledTab label="Report" style={{ minWidth: '33.3%', borderLeft: '3px solid white' }} />
                </StyledTabs>
              </Paper>
              <Typography variant="h4">
                {tab !== 2 ? setTotalAmount() : ''}
              </Typography>
            </Box>
            {tab === 0 && (
            <BudgetData
              {...props}
              onChange={() => {
		            api.get('projectsDetails', props.match.params.id).then((res) => {
			            setProjectData(res);
		            });
	            }}
              budget={projectData.budget || []}
            />
            )}
            {tab === 1 && (
            <FinancingData
              {...props}
              onChange={() => {
		            api.get('projectsDetails', props.match.params.id).then((res) => {
			            setProjectData(res);
		            });
	            }}
              financing={projectData.financing || []}
            />
            )}
            {tab === 2 && (
            <Report
              {...props}
              projectName={projectName}
            />
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default Budget;
