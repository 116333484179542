import React from 'react';
import CountriesTable from './CountriesTable';
import CreateUpdateCountry from './CreateUpdateCountry';

const Countries = () => {
  return (
    <>
      <CreateUpdateCountry />
      <CountriesTable />
    </>
  );
};
export default Countries;
