import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropzoneArea } from "material-ui-dropzone";
import Switch from "@material-ui/core/Switch";
import matchSorter from "match-sorter";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Toast from "../../components/Toast";
import Input from "../../components/Input";
import styles from "../Codes/CodesCreator/style";
import ButtonCustom from "../../components/Button";
import api from "../../instruments/api";
import DeleteItemPopup from "../ReceiptsList/DeleteItemPopup";
import { actions } from "../../contextStore/actions";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const BillForm = (props) => {
  const [fileToDelete, setFileToDelete] = useState({});
  const [isToastOpened, setToastState] = useState(false);
  const [messageToast, setMessageToast] = useState("");
  const [toastType, setToastType] = useState("success");
  const [files, setFiles] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [resp, setResp] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectError, setProjectError] = useState(false);

  const [codes, setCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [codeError, setCodeError] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [purchaseOrderError, setPurchaseOrderError] = useState(false);

  const [invoice, setInvoice] = useState("");
  const [invoiceError, setInvoiceError] = useState(false);
  const [filesFromBack, setFilesFromBack] = useState([]);

  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);

  const [payed, setPayed] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyError, setCompanyError] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [isPopupOpened, setPopupState] = useState(false);

  const [operation, setOperation] = useState("Add");

  const stateSetter = (data, setter) => {
    setter(data);
  };

  useEffect(() => {
    if (Object.keys(selectedItem).length && companies.length && codes.length && projects.length) {
      const { po, amount, invoice, date, is_payed, code, company, project, files } = selectedItem;
      setFilesFromBack(files);
      setPurchaseOrder(po);
      setAmount(amount);
      setInvoice(invoice);
      setDate(date.substring(0, 10)); // new Date(date * 1000).toISOString().substring(0, 10));
      setSelectedCode(codes.find((item) => item.id === code.id));
      setPayed(is_payed);
      setSelectedCompany(companies.find((item) => item.id === company.id));
      if (project) {
        setSelectedProject(projects.find((item) => item.id === project.id));
      }
    }
  }, [selectedItem, companies, codes, projects]);
  useEffect(() => {
    const {
      match: { params },
    } = props;
    if (params && params.id) {
      setOperation("Edit");
      const { id } = params;
      api.get("receipts", "", "", params.id).then((res) => {
        if (res && res.id) {
          setSelectedItem(res);
        }
      });
    }
    api.get("companies", { perPage: 500, type: "SUPPLIER", page: 0 }).then((res) => {
      stateSetter(res.results, setCompanies);
    });
    api.get("projects").then((res) => {
      stateSetter(res.results, setProjects);
    });
    api.get("codes").then((res) => {
      const { results } = res;
      const result = results.reduce((acc, current) => {
        const { children } = current;
        const array = children.map((item) => ({ ...item, categoryTitle: current.title }));
        return [...acc, ...array];
      }, []);
      stateSetter(result, setCodes);
    });
  }, []);

  async function createBill() {
    let error = false;
    if (!purchaseOrder) {
      error = true;
      setPurchaseOrderError(true);
    } else {
      setPurchaseOrderError(false);
    }
    if (!invoice) {
      error = true;
      setInvoiceError(true);
    } else {
      setInvoiceError(false);
    }
    if (!amount) {
      error = true;
      setAmountError(true);
    } else {
      setAmountError(false);
    }
    if (!selectedCode || selectedCode.code !== 91.1) {
      if (!selectedProject || !Object.keys(selectedProject).length) {
        error = true;
        setProjectError(true);
      } else {
        setProjectError(false);
      }
    } else {
      setProjectError(false);
    }
    if (!selectedCompany || !Object.keys(selectedCompany).length) {
      error = true;
      setCompanyError(true);
    } else {
      setCompanyError(false);
    }
    if (!selectedCode || !Object.keys(selectedCode).length) {
      error = true;
      setCodeError(true);
    } else {
      setCodeError(false);
    }
    if (!files || !files.length) {
      setMessageToast("upload at least one file");
      setToastType("error");
      setTimeout(() => {
        setToastState(true);
      }, 200);
    }
    if (error) {
      return;
    }
    setMessageToast("Bill was successfully created");
    setToastType("success");

    await Promise.all(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        const response = await api.postFiles("files", formData);
        setResp([...resp, response]);
        return response;
      })
    ).then((response) => {
      const data = {
        date,
        company_id: selectedCompany.id,
        po: purchaseOrder,
        invoice,
        amount: parseFloat(amount),
        project_id: selectedCode.code !== 91.1 ? selectedProject.id : null,
        code_id: selectedCode.id,
        is_payed: payed,
        files: [...response.map((item) => item.id), ...filesFromBack.map((item) => item.id)],
      };
      /*      if (selectedCode.code === '100.20') {
                delete data.project_id;
            } */
      if (selectedItem && selectedItem.id) {
        api.put("receipts", data, selectedItem.id).then((res) => {
          if (res.error) {
            setMessageToast(res.error.details.length ? res.error.details[0].message : res.error.message);
            setToastType("error");
          } else {
            props.history.push("/receipts");
          }
          setTimeout(() => {
            setToastState(true);
          }, 150);
        });
      } else {
        api.post("receipts", data).then((res) => {
          setToastState(true);
          if (res.error) {
            setMessageToast(res.error.details[0].message);
            setToastType("error");
          } else {
            props.history.push("/receipts");
          }
          setTimeout(() => {
            setToastState(true);
          }, 150);
        });
      }
    });
  }

  const handleClose = () => {
    props.history.push("/receipts");
  };

  const [keys, setKeys] = useState("123");

  function removeFileFromList(index) {
    setKeys(Math.random().toString());
    const fileList = [...files];
    fileList.splice(index, 1);
    setFiles(fileList);
  }

  function renderFilesList() {
    const arrayOfItems = files.map((item, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <ListItem key={`${item.name}${index}`}>
          <ListItemText style={{ maxWidth: 180 }} primary={item.name} />
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => {
                setFileToDelete({
                  index,
                  name: item.name,
                  isUploaded: false,
                });
                setPopupState(true);
              }}
              edge="end"
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
    return arrayOfItems;
  }

  function renderFileLink(fileId, fileName) {
    const downloadFile = () => {
      fetch(`/api/files/${fileId}`, {
        method: "GET",
      })
        .then((response) => {
          if (response.status === 404) {
            setMessageToast("File not found");
            setToastType("error");
            setTimeout(() => {
              setToastState(true);
            }, 200);
            return Promise.reject();
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    };
    return (
      <span
        style={{
          textDecoration: "underline",
          cursor: "pointer",
          color: "#854376",
        }}
        onClick={downloadFile}
      >
        {fileName}
      </span>
    );
  }

  function renderUploadedFileList() {
    if (filesFromBack.length) {
      const arrayOfItems = filesFromBack.map((item, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={`${item.name}${index}`}>
            <ListItemText style={{ maxWidth: 180 }} primary={renderFileLink(item.id, item.name)} />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => {
                  setFileToDelete({
                    index,
                    name: item.name,
                    isUploaded: true,
                  });
                  setPopupState(true);
                }}
                edge="end"
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      });
      return arrayOfItems;
    }
    return null;
  }

  const filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, { keys: ["code", "title"] });
  };
  const style = styles();
  return (
    <>
      <Box mb={5}>
        <Paper>
          <Box className={style.rootDashboardContainer} p={5}>
            <Typography
              onClick={() => {
                if (props.history.length > 2) props.history.goBack();
              }}
              style={{
                textAlign: "left",
                color: "#999898",
                marginBottom: 10,
                cursor: "pointer",
              }}
            >
              <PlayArrowIcon style={{ transform: "rotate(180deg)", position: "relative", top: 7 }} />
              Back
            </Typography>
            <Typography variant="h6" gutterBottom className={style.dashboardTitle}>
              {operation} receipt
            </Typography>
            <Box style={{ display: "flex" }}>
              <Box className={style.inputContainerBill}>
                <Box mb={2}>
                  <Input
                    label="Purchase Order"
                    helperText="Purchase Order field can't be empty"
                    error={purchaseOrderError}
                    value={purchaseOrder}
                    onChange={({ target: { value } }) => {
                      stateSetter(value, setPurchaseOrder);
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <Input
                    label="Invoice"
                    value={invoice}
                    helperText="Invoice field can't be empty"
                    error={invoiceError}
                    onChange={({ target: { value } }) => {
                      stateSetter(value, setInvoice);
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <Input
                    label="Amount"
                    helperText="Amount field can't be empty"
                    value={amount}
                    error={amountError}
                    onChange={({ target: { value } }) => {
                      if (/^[0-9]*\.?[0-9]*$/.test(value)) {
                        stateSetter(value, setAmount);
                      }
                    }}
                  />
                </Box>
                <Autocomplete
                  onChange={(_, value) => {
                    stateSetter(value, setSelectedProject);
                  }}
                  disabled={selectedCode && selectedCode.code === 91.1}
                  size="small"
                  options={projects}
                  getOptionLabel={(option) => option.name}
                  style={{ marginTop: 20 }}
                  value={selectedProject}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={projectError}
                      helperText={projectError ? "Project field can't be empty" : ""}
                      label="Project"
                      variant="outlined"
                    />
                  )}
                />
                <Autocomplete
                  onChange={(_, value) => {
                    stateSetter(value, setSelectedCompany);
                  }}
                  size="small"
                  style={{ marginTop: 20 }}
                  options={companies}
                  value={selectedCompany}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={companyError}
                      helperText={companyError ? "Company field can't be empty" : ""}
                      label="Company"
                      variant="outlined"
                    />
                  )}
                />
                <Autocomplete
                  onChange={(_, value) => {
                    stateSetter(value, setSelectedCode);
                  }}
                  style={{ marginTop: 20 }}
                  options={codes}
                  size="small"
                  groupBy={(option) => option.categoryTitle}
                  getOptionLabel={(option) =>
                    option.title && option.code ? `${option.title} — ${option.code}` : option.code
                  }
                  filterOptions={filterOptions}
                  value={selectedCode}
                  renderInput={(params) => (
                    <TextField
                      error={codeError}
                      helperText={codeError ? "Code field can't be empty" : ""}
                      {...params}
                      label="Code"
                      variant="outlined"
                    />
                  )}
                />
                <TextField
                  name={date}
                  id="date"
                  style={{ width: "100%", marginBottom: 15, marginTop: 15 }}
                  value={date}
                  onChange={({ target }) => {
                    stateSetter(target.value, setDate);
                  }}
                  label="Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <p style={{ margin: "0", fontSize: 20 }}>Payed:</p>
                  <Box style={{ marginLeft: 10 }}>
                    <Switch
                      onChange={() => {
                        stateSetter(!payed, setPayed);
                      }}
                      checked={payed}
                      classes={{
                        root: style.root,
                        switchBase: style.switchBase,
                        thumb: style.thumb,
                        track: style.track,
                        checked: style.checked,
                      }}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                style={{
                  marginLeft: 15,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <DropzoneArea
                    acceptedFiles={[
                      "application/pdf",
                      "application/msword",
                      "text/csv",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      "application/vnd.oasis.opendocument.spreadsheet",
                      "application/vnd.oasis.opendocument.text",
                      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                      "text/plain",
                      "application/vnd.ms-excel",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      "application/xml",
                    ]}
                    key={keys}
                    filesLimit={999}
                    initialFiles={files}
                    maxFileSize={10000000}
                    onChange={(filesUploaded) => {
                      const filteredArr = [...filesUploaded, ...files].reduce((acc, current) => {
                        const x = acc.find((item) => item.name === current.name);
                        if (!x) {
                          return acc.concat([current]);
                        }
                        return acc;
                      }, []);
                      setFiles(filteredArr);
                    }}
                  />
                  <List style={{ padding: 0 }} dense>
                    {renderFilesList()}
                  </List>
                  <List style={{ padding: 0 }} dense>
                    {renderUploadedFileList()}
                  </List>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <Button onClick={handleClose} color="primary" style={{ marginRight: 8 }}>
                    Cancel
                  </Button>

                  <ButtonCustom
                    onClick={() => {
                      createBill();
                    }}
                  >
                    Save
                  </ButtonCustom>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Toast
        open={isToastOpened}
        closeToast={() => {
          setToastState(false);
        }}
        message={messageToast}
        type={toastType}
      />
      <DeleteItemPopup
        handleClose={() => setPopupState(false)}
        callback={() => {
          const { index, isUploaded } = fileToDelete;
          if (fileToDelete && isUploaded) {
            const destructedList = [...filesFromBack];
            destructedList.splice(index, 1);
            setFilesFromBack(destructedList);
          } else {
            removeFileFromList(index);
          }
          setPopupState(false);
        }}
        open={isPopupOpened}
      >
        <DialogContent>Are you sure you want to delete a file with name:</DialogContent>
        <DialogContent style={{ textAlign: "center" }}>
          {'"'}
          {fileToDelete.name}
          {'"'}
        </DialogContent>
      </DeleteItemPopup>
    </>
  );
};
export default BillForm;
