import React, {useEffect, useRef, useState} from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import {Typography} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import api from '../../instruments/api';
import styles from '../Budget/style';
import ReactVirtualizedTable from '../../components/MUITable';
import columns from './tableColumns';

const makeLink = (projectId, codeID, title, history) => {
  return (
    <Link
      onClick={() => {
	      window.previousLocation = window.location.pathname;
	      history.push(`/projects/report/${projectId}/receipts/${codeID}`);
      }}
      style={{
			  color: 'rgb(170, 78, 155)',
			  textAlign: 'left',
			  marginBottom: 3,
	      cursor: 'pointer',
      }}
    >
      {title}
    </Link>
  );
};
const ReportDetailBudget = (props) => {
  const style = styles();
  const [width, setWidth] = useState(window.innerWidth);
  const [data, setData] = useState([]);
  const [name, setProjectName] = useState('');
  const rowColoredDetails = useRef([]);
  const { type, match, subType } = props;

  const subTitles = {
  	detail: 'Detail',
    summary: 'Summary',
    numbers: 'PO Numbers'
  };

  const defaultTitle = 'Detail';

  useEffect(() => {
	  api.getById('projects', match.params.id).then((res) => {
		  setProjectName(res.name || '');
	  });
  	api.get('projectsReport', match.params.id, type)
		  .then((res) => {
		  	const { results } = res;
		  	if (type === 'tax-shelter') {
                const fieldNamesTax=[
                    'be_budget',
                    'be_non_production_cost',
                'be_production_cost',
                'grand_total',                
                'non_belgian_cost',              
                'remain_be',                
                'total_belgium',
                'total_budget_production',
                ];
		  		if (subType === 'detail') {
				  setData(results[0].results.map((item, ind) => {
				    if (item.id === 0) rowColoredDetails.current.push(ind);
				    let coProducerData = {};
              item.co_producers.forEach((coProducer, index) => {
                coProducerData = {
                  ...coProducerData,
                  [`co_producers_${index}_total_cost_report`]: coProducer.total_cost_report?.toFixed(2),
                  [`co_producers_${index}_remain_budget`]: coProducer.remain_budget?.toFixed(2),
                  [`co_producers_${index}_budget`]: coProducer.budget?.toFixed(2),
                };
              });
              return ({
                ...item,
                  
                  ...fieldNamesTax.reduce((ac,a) => ({...ac,[a]:item[a]?.toFixed(2)}),{}),
                code: makeLink(match.params.id, item.id, item.code?.toFixed(2), props.history),
                title: makeLink(match.params.id, item.id, item.title, props.history),
                ...coProducerData,
              });
            }));
				  }
		  		if (subType === 'summary') {
					  setData(results[2].results.map((item, ind) => {
              if (item.id === 0) rowColoredDetails.current.push(ind);
              let coProducerData = {};
              item.co_producers.forEach((coProducer, index) => {
                coProducerData = {
                  ...coProducerData,
                  [`co_producers_${index}_total_cost_report`]: coProducer.total?.toFixed(2),
                  [`co_producers_${index}_budget`]: coProducer.budget?.toFixed(2),
                };
              });
					    return ({
                ...item,
                            ...fieldNamesTax.reduce((ac,a) => ({...ac,[a]:item[a]?.toFixed(2)}),{}),
                code: makeLink(match.params.id, item.id, item.code?.toFixed(2), props.history),
                title: makeLink(match.params.id, item.id, item.title, props.history),
                ...coProducerData,
              });
            }));
				  }
				  if (subType === 'numbers') {
					  setData(results[1].results.map((item) => ({
						  ...item,
                          amount:item.amount?.toFixed(2),
						  code: makeLink(match.params.id, item.id, item.code?.toFixed(2), props.history),
						  title: makeLink(match.params.id, item.id, item.title, props.history),
						  date: item.date.substring(0,10), //new Date(item.date * 1000).toISOString().substring(0, 10),
						  companyAddress: item.company ? item.company.address : '',
						  companyName: item.company ? item.company.name : '',
					  })));
				  }
				  return;
			  }
              const fieldNames=[
                  'budget_be', 
                  'total_budget_flemish_costs',
              'total_budget_not_flemish_costs',
              'total_flemish_costs',
              'total_flemish_not_flemish',
              'total_not_flemish_costs',
                  'difference',
                  'cost_eligible',
                  'cost_non_eligible',
                  'non_flemish'                 
                  
              ];
			  setData(results.map((item, ind) => {
          if (item.id === 0) rowColoredDetails.current.push(ind);
                  return ({
                    ...item,
                    ...fieldNames.reduce((acc, curr) => (acc[curr] = item[curr]?.toFixed(2), acc), {}),
                    code: makeLink(match.params.id, item.id, item.code?.toFixed(2), props.history),
                    title: makeLink(match.params.id, item.id, item.title, props.history),
                });
               
        }));
              
		  });
	  function updateSize() {
		  setWidth(window.innerWidth);
	  }
	  window.addEventListener('resize', updateSize);
	  return () => window.removeEventListener('resize', updateSize);
  }, []);

  let coProducersTotal = [];
  const coProducersRemainCO = []; const 
    coProducersBudjet = [];
  if (data[0] && data[0].co_producers) {
	  const coProducers = data[0]?.co_producers;
	  coProducersTotal = coProducers.map((coProducer, index) => {
		  coProducersRemainCO.push({
			  width: 225,
			  label: `Remain ${coProducer.name}`,
			  dataKey: `co_producers_${index}_remain_budget`,
		  });
		  coProducersBudjet.push({
			  width: 225,
			  label: `${coProducer.name} Budget`,
			  dataKey: `co_producers_${index}_budget`,
		  });
	  	return {
        width: 225,
        label: coProducer.name,
        dataKey: `co_producers_${index}_total_cost_report`,
      };
	  });
  }
  const detailsColumn = [
    {
      width: 100,
      label: 'Code',
      dataKey: 'code',
    },
    {
      width: 400,
      label: 'Description',
      dataKey: 'title',
    },
    {
      width: 200,
      label: 'BE Production',
      dataKey: 'be_production_cost',
    },
    {
      width: 200,
      label: 'BE Non Production',
      dataKey: 'be_non_production_cost',
    },
    {
      width: 200,
      label: 'Non BE',
      dataKey: 'non_belgian_cost',
    }, {
      width: 200,
      label: 'Total BE',
      dataKey: 'total_belgium',
    }, ...coProducersTotal,
    {
      width: 200,
      label: 'Grand Total',
      dataKey: 'grand_total',
    },
    {
      width: 200,
      label: 'Remain BE',
      dataKey: 'remain_be',
    }, ...coProducersRemainCO,
    {
      width: 200,
      label: 'BE Budget',
      dataKey: 'be_budget',
    }, ...coProducersBudjet, {
      width: 200,
      label: 'Total Budget Production',
      dataKey: 'total_budget_production',
    },
  ];

  const summaryColumn = [
    {
      width: 70,
      label: 'Code',
      dataKey: 'code',
    },
    {
      width: 350,
      label: 'Description',
      dataKey: 'title',
    },
    {
      width: 150,
      label: 'Total',
      dataKey: 'grand_total',
    }, {
      width: 150,
      label: 'Total BE',
      dataKey: 'total_belgium',
    }, ...coProducersTotal,
    {
      width: 150,
      label: 'BE Budget',
      dataKey: 'be_budget',
    },
    ...coProducersBudjet,
    {
      width: 150,
      label: 'Total Budget Production',
      dataKey: 'total_budget_production',
    },
  ];
  return (
    <>
      <Box>
        <Paper>
          <Box className={style.rootDashboardContainer} style={{ minWidth: 15 }} p={5}>
            <Typography
              onClick={() => {
	            	props.history.push(`/projects/report/${props.match.params.id}`);
	            }}
              style={{
	              textAlign: 'left', color: '#999898', marginBottom: 10, cursor: 'pointer'
	            }}
            >
              <PlayArrowIcon style={{ transform: 'rotate(180deg)', position: 'relative', top: 7 }} />
              {name}
              {' '}
              Report
            </Typography>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h6"
                className={style.dashboardTitle}
                style={{ marginBottom: 0, fontSize: 25 }}
              >
                {/* {name} */}
                {/* {' '} */}
                {type === 'vaf' ? 'VAF' : type === 'screen-flanders' ? 'Screen Flanders' : 'Tax Shelter'}
              </Typography>
              <Box ml={1} style={{ display: 'none' }}>
                <IconButton size="small">
                  <PrintOutlinedIcon style={{ color: 'black' }} />
                </IconButton>
                <IconButton size="small">
                  <GetAppOutlinedIcon style={{ color: 'black' }} />
                </IconButton>
              </Box>
            </Box>
            <Typography
              className={style.inputTypography}
              style={{ marginBottom: 15, fontSize: 15, fontWeight: 600 }}
            >
              {subType ? subTitles[subType] : defaultTitle}
            </Typography>
            <Box style={{
	            overflowX: (type === 'tax-shelter' && subType === 'numbers' ? 'hidden' : 'scroll'),
	            width: `calc( ${width}px - 240px - 240px)`,
	            marginTop: -10
            }}
            >
              <Box style={{ width: (type === 'tax-shelter' && subType === 'detail' ? 1600 + (coProducersTotal.length * 800) : 1290 + (coProducersTotal.length * 400)) }}>
                <ReactVirtualizedTable
                  columns={type === 'tax-shelter' ? subType === 'detail' ? detailsColumn : subType === 'summary' ? summaryColumn : columns[type][subType] : columns[type]}
                  colourRows={rowColoredDetails.current}
                  data={data}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default ReportDetailBudget;
