import { makeStyles } from '@material-ui/core/styles';


const styles = makeStyles(() => ({
  inputContainer: {
    display: 'flex',
    width: '60%',
    marginBottom: 15
  },
  rootDashboardContainer: {
    minWidth: 900,
	  minHeight: 'calc(100vh - 160px)',
  },
  inputTypography: {
    minWidth: 100,
    maxWidth: 150,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  dashboardTitle: {
    color: '#aa4e9b',
    textAlign: 'left',
    marginBottom: 25
  }
}));
export const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  switchBase: {
    color: '#7d7d7d',
    '&$checked': {
      color: '#26c952',
    },
    '&$checked + $track': {
      backgroundColor: '#26c952',
    },
  },
  checked: {},
  track: {},
});
export default styles;
