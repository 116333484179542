import React from 'react';
import { Box, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import { actions } from '../../../contextStore/actions';

const Report = (props) => {
  return (
    <>
      <Box style={{
	    	display: 'flex',
	      justifyContent: 'space-between',
	      marginTop: 25
	    }}
      >
        <LinkItem
          {...props}
          title="Tax Shelter"
          links={[
	      {
	      	title: 'Detail',
		      href: `/projects/report/${props.match.params.id}/tax-shelter/detail-budget`,
              report_type: 'tax-shelter',
              page_id: 1,
              project_id: props.match.params.id,
              fileName: `${props.projectName}-tax-shelter-details.csv`,
	      },
            {
              title: 'PO numbers',
              href: `/projects/report/${props.match.params.id}/tax-shelter/po-numbers`,
              report_type: 'tax-shelter',
              page_id: 2,
              project_id: props.match.params.id,
              fileName: `${props.projectName}-tax-shelter-PO-numbers.csv`,
            },
	      {
		        title: 'Summary',
		        href: `/projects/report/${props.match.params.id}/tax-shelter/summary`,
              report_type: 'tax-shelter',
              page_id: 3,
              project_id: props.match.params.id,
              fileName: `${props.projectName}-tax-shelter-summary.csv`,
	      },
          ]}
        />
        <LinkItem
          {...props}
          title="VAF"
          links={[
			    {
				    title: 'Detail',
				    href: `/projects/report/${props.match.params.id}/vaf`,
              report_type: 'vaf',
              page_id: 1,
              project_id: props.match.params.id,
              fileName: `${props.projectName}-vaf-detail.csv`,
			    },
              {
                  title: 'VAF-schijf',
                  href: '#',
                  report_type: 'vaf-schijf',
                  page_id: 1,
                  project_id: props.match.params.id,
                  fileName: `${props.projectName}-vaf-schijf.xlsx`,
              },
		    ]}
        />
        <LinkItem
          {...props}
          title="Screen Flanders"
          links={[
	          {
		          title: 'Detail',
		          href: `/projects/report/${props.match.params.id}/screen-flanders`,
              report_type: 'screen-flanders',
              page_id: 1,
              project_id: props.match.params.id,
              fileName: `${props.projectName}-screen-flanders-summary.csv`,
	          },
          ]}
        />
      </Box>
    </>
  );
};

export default Report;

export const LinkItem = (props) => {
  const { title, links } = props;
  const downloadReport = (report_type, page_id, project_id, fileName) => {
    fetch(
      `/api/projects/${project_id}/reports/${report_type}/download?page_id=${page_id}`,
      {
        method: 'GET',
      },
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };
  return (
    <Box style={{ width: '30%' }}>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">
          {title}
        </Typography>
        <Box style={{ display: 'none' }}>
          <IconButton size="small">
            <PrintOutlinedIcon style={{ color: 'black' }} />
          </IconButton>
          <IconButton size="small">
            <GetAppOutlinedIcon style={{ color: 'black' }} />
          </IconButton>
        </Box>
      </Box>
      <div style={{
	    	width: '100%',
		    height: 4,
	      borderRadius: 5,
		    backgroundColor: 'rgb(170, 78, 155)',
	      marginBottom: 10,
	    }}
      />
      <Box style={{
        display: 'flex', flexDirection: 'column',
      }}
      >
        {links.map((item) => (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
          >
            <Link
              onClick={() => {
            	props.history.push(item.href);
              }}
              style={{
				      color: 'rgb(170, 78, 155)',
				      textAlign: 'left',
	            marginBottom: 3,
	            cursor: 'pointer',
              }}
            >
              {item.title}
            </Link>
            <Tooltip title="Download report" aria-label="add">
              <CloudDownloadIcon
                style={{ cursor: 'pointer',color:'rgb(170, 78, 155)' }}
                onClick={() => downloadReport(item.report_type, item.page_id, item.project_id, item.fileName)}
              />
            </Tooltip>
          </div>
	      ))}
      </Box>
    </Box>
  );
};
