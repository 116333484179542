import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './style';
import ReactVirtualizedTable from '../../../components/MUITable';
import api from '../../../instruments/api';
import { ContextApp } from '../../../contextStore/reducer';
import { actions } from '../../../contextStore/actions';

const CountriesTable = () => {
  const style = styles();
  const [tableData, setTableData] = useState([]);
  const { dispatch, state } = useContext(ContextApp);
  const TABLE_COLUMNS = [
    {
      width: 600,
      label: 'Name',
      dataKey: 'name',
    }, {
      width: 100,
      label: 'Action',
      dataKey: 'action',
    },
  ];
  const makeInnerBtn = (item) => {
    return (
      <>
        <Tooltip title="Delete country" aria-label="add">
          <DeleteIcon
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              await api.delete('countries', item.id);
              dispatch(actions.setCode(item));
            }}
          />
        </Tooltip>
      </>
    );
  };
  const { selectedCode, createdCode } = state;
  useEffect(() => {
    if (Object.keys(createdCode).length) {
      const newArray = [...tableData, {
        ...createdCode,
        action: makeInnerBtn({
          id: createdCode.id,
          code: createdCode.code,
          title: createdCode.title
        })
      }];
      setTableData(newArray);
      dispatch(actions.setCreatedCodeToList({}));
    }
  }, [createdCode]);
  useEffect(() => {
    const copyOfDataArray = [...tableData];
    if (Object.keys(selectedCode).length && selectedCode.isChanged) {
      const changedItemIndex = tableData.findIndex((item) => item.id === selectedCode.id);
      delete selectedCode.isChanged;
      selectedCode.action = makeInnerBtn({
        id: selectedCode.id,
        code: selectedCode.code,
        title: selectedCode.title
      });
      copyOfDataArray.splice(changedItemIndex, 1, selectedCode);
      setTableData(copyOfDataArray);
    }
  }, [selectedCode]);
  useEffect(() => {
    api.get('countries')
      .then((res) => {
        const { results } = res;
        const array = results.map((item) => {
          return { ...item, action: makeInnerBtn(item) };
        });
        setTableData(array);
      });
  }, [selectedCode]);
  return (
    <Box>
      <Paper>
        <Box className={style.rootDashboardContainer} p={5}>
          <Typography variant="h6" gutterBottom className={style.dashboardTitle}>
            Countries Overview
          </Typography>
          <ReactVirtualizedTable columns={TABLE_COLUMNS} data={tableData} />
        </Box>
      </Paper>
    </Box>
  );
};
export default CountriesTable;
