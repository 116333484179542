import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import api from '../../../instruments/api';
import Input from '../../../components/Input';
import ButtonCustom from '../../../components/Button';
import Toast from '../../../components/Toast';
import { ContextApp } from '../../../contextStore/reducer';
import { actions } from '../../../contextStore/actions';


export default function CoProducersCreateUpdateForm() {
  const [toastOpened, closeToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');


  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);

  const [vatNumber, setVatNumber] = useState('');
  const [vatNumberError, setVatNumberError] = useState(false);

  const [notes, setNotes] = useState('');

  const [type, setType] = useState('');
  const [typeError, setTypeError] = useState(false);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [countryError, setCountryError] = useState(false);

  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [id, setId] = useState('');
  const { state, dispatch } = useContext(ContextApp);
  const { isPopupOpened, selectedItem } = state;
  useEffect(() => {
    if (countries && countries.length && selectedCountry && Object.keys(selectedCountry)) {
      const country = countries.find((item) => item.id === selectedCountry.id);
      setRegions(country ? country.regions : []);
      setSelectedRegion(country
        ? country.regions.find((item) => item.id === selectedItem.region_id) : [{}]);
    }
  }, [selectedCountry, countries]);
  const clearState = () => {
    setTimeout(() => {
      setId('');
      setNotes('');
      setSelectedCountry(countries[0]);
      setAddress('');
      setName('');
      setType('CO_PRODUCER');
      setVatNumber('');
      setNameError(false);
      setAddressError(false);
      setVatNumberError(false);
      setTypeError(false);
      setCountryError(false);
    }, 500);
  };
  useEffect(() => {
    const {
      // eslint-disable-next-line no-shadow,camelcase
      country, address, contacts, id, name, type, vat_number
    } = selectedItem;
    if (Object.keys(selectedItem).length) {
      setId(id);
      setNotes(contacts.map((item) => item.note).join('\n'));
      setSelectedCountry(country);
      setAddress(address);
      setName(name);
      setType(type);
      setVatNumber(vat_number);
    } else {
      clearState();
    }
  }, [selectedItem]);
  useEffect(() => {
    api.get('countries').then((res) => {
      const { results } = res;
      setCountries(results);
      setSelectedCountry(results[0]);
    });
  }, []);

  const handleClose = () => {
    dispatch(actions.selectItem({}));
    dispatch(actions.setPopupState());
    clearState();
  };

  function buildNodesArray() {
    return notes.split('\n')
      .filter((item) => item !== '')
      .map((item) => ({ note: item }));
  }

  const setupToast = (message, typeOfToast) => {
    setToastMessage(message);
    setToastType(typeOfToast);
  };
  const setCompany = () => {
    let error = false;
    if (!name) {
      error = true;
      setNameError(true);
    } else {
      setNameError(false);
    }
    if (!address) {
      error = true;
      setAddressError(true);
    } else {
      setAddressError(false);
    }
    if (!type) {
      error = true;
      setTypeError(true);
    } else {
      setTypeError(false);
    }
    if (!selectedCountry) {
      error = true;
      setCountryError(true);
    } else {
      setCountryError(false);
    }
    if (!vatNumber) {
      error = true;
      setVatNumberError(true);
    } else {
      setVatNumberError(false);
    }
    if (error) {
      return;
    }
    if (!id) {
      setupToast('Company was added', 'success');
      api.post('companies', {
        type,
        name,
        address,
        vat_number: vatNumber,
        country_id: selectedCountry.id,
        region_id: selectedRegion && Object.keys(selectedRegion).length ? selectedRegion.id : null,
        contacts: notes.length ? buildNodesArray() : []
      }, id).then((res) => {
        if (res && res.type) {
          dispatch(actions.setPopupState());
          closeToast(true);
          clearState();
          dispatch(actions.selectItem({}));
        } else if (res && res.error && res.error.message === 'Validation Exception') {
          setupToast(res.error.details[0].message, 'error');
          setTimeout(() => {
            closeToast(true);
          }, 200);
        }
      });
    } else {
      setupToast('Company was edited', 'success');

      api.put('companies', {
        type,
        name,
        address,
        vat_number: vatNumber,
        region_id: selectedRegion && Object.keys(selectedRegion).length ? selectedRegion.id : null,
        country_id: selectedCountry.id,
        contacts: notes.length ? buildNodesArray() : []
      }, id).then((res) => {
        if (res && res.type) {
          closeToast(true);
          dispatch(actions.setPopupState());
          clearState();
          dispatch(actions.selectItem({}));
        } else if (res && res.error && res.error.message === 'Validation Exception') {
          setupToast(res.error.details[0].message, 'error');
          setTimeout(() => {
            closeToast(true);
          }, 200);
        }
      });
    }
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isPopupOpened}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle
          id="max-width-dialog-title"
        >
          {id ? 'Edit co-producer' : 'Add new co-producer'}
        </DialogTitle>
        <DialogContent>
          <Box style={{ marginTop: 20 }}>
            <Input
              value={name}
              helperText="name can't be empty"
              error={nameError}
              onChange={({ target: { value } }) => setName(value)}
              label="Name"
            />
          </Box>
          <Box style={{ marginTop: 20 }}>
            <Input
              value={address}

              helperText="address can't be empty"
              error={addressError}
              onChange={({ target: { value } }) => setAddress(value)}
              label="Address"
            />
          </Box>
          <Box style={{ marginTop: 20 }}>
            <Input
              value={vatNumber}
              error={vatNumberError}
              helperText="Vat number can't be empty"
              onChange={({ target: { value } }) => setVatNumber(value)}
              label="Vat number"
            />
            <Autocomplete
              onChange={(_, value) => {
                setType(value);
              }}
              value={type}
              size="small"
              disabled
              options={['SUPPLIER', 'FUND']}
              getOptionLabel={(option) => option}
              style={{ marginTop: 20 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={typeError}
                  helperText={typeError ? 'Type is required field' : ''}
                  label="Type"
                  variant="outlined"
                />
              )}
            />
            <Autocomplete
              onChange={(_, value) => {
                setSelectedCountry(value);
              }}
              size="small"
              value={selectedCountry}
              options={countries}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 20 }}
              renderInput={(params) => (
                <TextField
                  error={countryError}
                  {...params}
                  helperText={countryError ? 'Country is required field' : ''}
                  label="Country"
                  variant="outlined"
                />
              )}
            />
            <Autocomplete
              onChange={(_, value) => {
                setSelectedRegion(value);
              }}
              size="small"
              value={selectedRegion || {}}
              options={regions}
              disabled={!regions || !regions.length}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 20 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Region"
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box style={{ marginTop: 20 }}>
            <Input
              label="Contacts"
              value={notes}
              multiline
              onChange={({ target: { value } }) => {
                setNotes(value);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{
          display: 'flex', justifyContent: 'center', marginBottom: 20
        }}
        >
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <ButtonCustom onClick={setCompany}>
            {id ? 'Save' : 'Add co-producer'}
          </ButtonCustom>
        </DialogActions>
      </Dialog>
      <Toast
        message={toastMessage}
        type={toastType}
        open={toastOpened}
        closeToast={() => {
          closeToast(false);
        }}
      />
    </>
  );
}
