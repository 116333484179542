import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EditIcon from '@material-ui/icons/Edit';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../instruments/api';
import styles from '../ReceiptsList/ReceiptsOverview/style';
import Toast from '../../components/Toast';

const defaultDataArray = [[], [], [], [], [], [], [], [], [], [], [], []];
const Overhead = () => {
  const style = styles();
  const [selectedYear, setYear] = useState(new Date().getFullYear());
  const [projectNames, setProjectNames] = useState([]);
  const [newDataRequestFlag, setNewDataRequestFlag] = useState(false);
  const [data, setData] = useState([]);
  const [size, setSize] = useState(window.innerWidth - 245 - 80);
  useEffect(() => {
    function updateSizeContainer() {
      setSize(window.innerWidth - 245 - 80);
    }

    window.addEventListener('resize', updateSizeContainer);
    updateSizeContainer();
    api.get('overhead', { year: selectedYear }).then((res) => {
      const { results } = res;
      const names = (results.map((item) => item.name));
      const itemsToRemove = results.map((item) => item.name);
      const firstPartOfData = results.reduce((acc, current) => {
        current.months.forEach((item, index) => {
          acc[index].push({ ...item, id: current.id });
        });
        return acc;
      }, [[], [], [], [], [], [], [], [], [], [], [], []]);
      api.get('projects').then((res) => {
        const { results } = res;
        const filteredResult = results.filter((item) => !itemsToRemove.includes(item.name));
        setProjectNames([...names, ...filteredResult.map((item) => item.name)]);
        const array = defaultDataArray;
        for (let a = 0; a < 12; a++) {
          array[a] = filteredResult.map((item) => ({
            date: `${selectedYear}-${(a + 1).toString().length === 1 ? `0${a + 1}` : a + 1}-01`,
            id: item.id,
            percentage: 0
          }));
        }
        setData(firstPartOfData.map((item, index) => {
          return [...item, ...array[index]];
        }));
      });
    });
    return () => window.removeEventListener('resize', updateSizeContainer);
  }, [selectedYear, newDataRequestFlag]);
  return (
    <>
      <Box>
        <Paper>
          <Box
            className={style.rootDashboardContainer}
            style={{ width: size }}
            p={5}
          >
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" gutterBottom className={style.dashboardTitle}>
                Overhead
              </Typography>
              <ControlledOpenSelect setYear={setYear} year={selectedYear} />
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              <ProjectsNameOverview projectNames={projectNames} />
              {
								data.map((item, index) => (
  <ProjectsPercentagePerMonth
    key={item[0] ? `${item[0].date}-${Math.random()}` : ''}
    index={index}
    data={item}
    onChangeRequest={() => {
										  setNewDataRequestFlag(!newDataRequestFlag);
    }}
  />
								))
							}
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default Overhead;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
}));

export const ControlledOpenSelect = ({ setYear, year }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function setMenuItems() {
    const thisYear = new Date().getFullYear();
    const arrayOfYears = [];
    for (let i = thisYear - 3; i <= thisYear + 5; i++) {
      arrayOfYears.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return arrayOfYears;
  }

  return (
    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h4" style={{ marginRight: 15, color: 'rgba(0, 0, 0, 0.54)' }}>
        {year}
      </Typography>
      <FormControl className={classes.formControl}>
        <InputLabel
          style={{
					  cursor: 'pointer',
					  position: 'relative',
					  transform: 'none'
          }}
          onClick={handleOpen}
          id="demo-controlled-open-select-label"
        >
          <DateRangeIcon
            style={{ width: 30, height: 30 }}
          />
        </InputLabel>
        {open && (
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={year}
          style={{ position: 'absolute' }}
          onChange={handleChange}
        >
          {
							setMenuItems()
						}
        </Select>
        )}
      </FormControl>
    </Box>
  );
};

export const ProjectsNameOverview = ({ projectNames }) => {
  return (
    <>
      <Box>
        <Box
          className="ProjectsNameOverviewTitles"
        >
          <Typography>
            Projects
          </Typography>
        </Box>
        {projectNames.map((item) => (
          <Box
            key={item}
            className="ProjectsNameOverviewNames"
            pr={8}
          >
            <Tooltip title={item}>
              <Typography style={{ paddingRight: 0 }}>
                {item.length > 20 ? `${item.substr(0, 17)}...` : item}
              </Typography>
            </Tooltip>
          </Box>
        ))}
      </Box>
    </>
  );
};
export const ProjectsPercentagePerMonth = (props) => {
  const { data, onChangeRequest } = props;
  const [edit, setEditStatement] = useState(false);
  const [innerData, setInnerData] = useState([]);
  const [widthCalculated, setSize] = useState(window.innerWidth);
  const [toastState, setToastState] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  useEffect(() => {
    setInnerData(data);
    function updateSize() {
      setSize((window.innerWidth - 245 - 80 - 80 - 200) / 12);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [data]);
  return (
    <>
      <Box style={{ width: widthCalculated }}>
        <Box
          className="ProjectsNameOverviewValueTitle"
        >
          <Typography
            style={{ textAlign: 'center', width: 'inherit' }}
          >
            {new Date(data[0] ? data[0].date : '').toLocaleString('default', { month: 'short' })}
          </Typography>
        </Box>
        {data.map((item, index) => (
          <Box
            key={JSON.stringify(item)}
            className="ProjectsNameOverviewValue"
          >
            <input
              onChange={({ target: { value } }) => {
							  // if (/^[0-9]*[,.]?[0-9]*$/.test(value)) {
							  if (/^[0-9]*\.?[0-9]*$/.test(value)) {
							    const dataChanger = [...innerData];
							    dataChanger[index] = { ...dataChanger[index], percentage: value };
							    setInnerData(dataChanger);
							  }
              }}
              value={innerData.length ? edit ? innerData[index].percentage : `${innerData[index].percentage}%` : ''}
              disabled={!edit}
              className="ProjectsNameOverviewInput"
              type="text"
            />
          </Box>
        ))}
        {edit
				  ? (
  <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
    <CancelOutlinedIcon onClick={() => {
							  setInnerData(data);
							  setEditStatement(false);
    }}
    />
    <CheckIcon
      onClick={() => {
								  const value = {
								    date: innerData[0].date,
								    values: innerData.map((item) => ({ project_id: item.id, percentage: parseInt(item.percentage, 10) }))
								  };
								  api.post('overhead', value).then((res) => {
								    const { created, error } = res;
								    if (error && error.code === 400) {
								      setToastMessage(error.message);
								      setToastState(true);
								    }
								    if (created === true) {
								      onChangeRequest();
								      setEditStatement(false);
								    }
								  });
      }}
    />
  </Box>
				  )
				  : (
  <EditIcon onClick={() => {
						  setEditStatement(true);
  }}
  />
				  )}
      </Box>
      <Toast
        type="error"
        closeToast={() => setToastState(false)}
        open={toastState}
        message={toastMessage}
      />
    </>
  );
};
