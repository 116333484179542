import React, {
  useContext, useEffect, useLayoutEffect, useState
} from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogContent from '@material-ui/core/DialogContent';
import Pagination from '@material-ui/lab/Pagination';
import styles from './style';
import ReactVirtualizedTable from '../../../components/MUITable';
import api from '../../../instruments/api';
import useRowStyles from '../../../components/MUICollapsibleTable/style';
import ButtonCustom from '../../../components/Button';
import DeleteItemPopup from '../DeleteItemPopup';
import Toast from '../../../components/Toast';

const ReceiptsOverview = (props) => {
  const style = styles();
  const [itemToDelete, setItemToDelete] = useState({});
  const [isPopupOpened, setPopupState] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isToastOpened, setToastState] = useState(false);
  const [messageToast, setToastMessage] = useState('');
  const [size, setSize] = useState(0);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const perPage=10;
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  const TABLE_COLUMNS = [
    {
      width: 170,
      label: 'Date',
      dataKey: 'date',
    },
	  {
		  width: 300,
		  label: 'Company',
		  dataKey: 'company',
	  },
	  {
		  width: 300,
		  label: 'PO',
		  dataKey: 'po',
	  },
	  {
		  width: 300,
		  label: 'Invoice',
		  dataKey: 'invoice',
	  },
    {
      width: 200,
      label: 'Project',
      dataKey: 'project',
    },
    {
      width: 340,
      label: 'Code+Description',
      dataKey: 'code_description',
    },
	  {
		  width: 150,
		  label: 'Amount',
		  dataKey: 'amount',
	  },
	  {
		  width: 100,
		  label: 'Payed',
		  dataKey: 'payed',
	  },
    {
      width: 100,
      label: 'Action',
      dataKey: 'action',
    },
  ];
  const makeInnerBtn = (item) => {
    return (
      <>
        <Tooltip title="Edit receipt" aria-label="add">
          <EditIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
						  props.history.push(`/receipts/edit/${item.id}`);
            }}
          />
        </Tooltip>
        <Tooltip title="Delete receipt" aria-label="add">
          <DeleteIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
	            setItemToDelete(item);
	            setPopupState(true);
            }}
          />
        </Tooltip>
      </>
    );
  };
  const prepareData = (data) => {
    return data.map((item) => ({
	    id: item.id,
      date: item.date?item.date.substring(0,10):null,// new Date(item.date * 1000).toISOString().substring(0, 10),
      project: item && item.project ? item.project.name : '',
      amount: item.amount.toFixed(2),
      isPayed: item.is_payed,
      code_description: `${item.code.code.toFixed(2)} ${item.code.title}`,
      company: item.company.name,
	    payed: (<MakeStatusBtn item={item} />),
      action: makeInnerBtn(item),
	    invoice: item.invoice,
	    po: item.po,
    }));
  };
  function recallItem() {
    api.get('receipts', { page, per_page: perPage }).then((res) => {
      const { results, total } = res;
      setTableData(prepareData(results));
      setTotal(total);
    });
  }
  useEffect(() => {
    recallItem();
  }, [page]);

  return (
    <>
      <Box>
        <Paper>
          <Box className={style.rootDashboardContainer} style={{ width: (size - 245 - 80) }} p={5}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" gutterBottom className={style.dashboardTitle}>
                Receipts Overview
              </Typography>
              <ButtonCustom onClick={() => {
							  props.history.push('/receipts/add');
              }}
              >
                Add new receipt
              </ButtonCustom>
            </Box>
            <Box style={{
              overflowX: 'scroll', display: window.innerWidth > 1700 ? 'flex' : 'block', justifyContent: 'center', paddingBottom: 30, overflow: '-moz-scrollbars-vertical' 
            }}
            >
              <Box style={{ width: 1900 }}>
                <ReactVirtualizedTable columns={TABLE_COLUMNS} data={tableData} />
              </Box>
            </Box>
            <Pagination
              style={{
                transform: 'translate(-50%)',
			          marginLeft: '50%',
			          width: 350,
			          marginTop: 10,
			          display: 'flex',
			          justifyContent: 'center'
		          }}
              defaultPage={page + 1}
              count={Math.ceil(total / perPage)}
              onChange={(event, value) => {
			          setPage(value - 1);
		          }}
            />
          </Box>
        </Paper>
      </Box>
      <DeleteItemPopup
        handleClose={() => setPopupState(false)}
        callback={() => {
	        setToastMessage('You successfully delete a receipt');
        	api.delete('receipts', itemToDelete.id).then(() => {
				    setPopupState(false);
		        recallItem();
		        setToastState(true);
	        });
        }}
        open={isPopupOpened}
      >
        <DialogContent>
          Are you sure you want to delete a receipt
        </DialogContent>
      </DeleteItemPopup>
      <Toast
        open={isToastOpened}
        closeToast={() => {
			    setToastState(false);
		    }}
        message={messageToast}
        type="success"
      />
    </>
  );
};
// eslint-disable-next-line react/prop-types
const MakeStatusBtn = ({ item }) => {
  // eslint-disable-next-line react/prop-types,camelcase
  const { is_payed, id } = item;
  const classes = useRowStyles();
  const [isPayed, setIsPayed] = useState(false);
  useEffect(() => {
    setIsPayed(is_payed);
  }, []);
  return (
    <Switch
      onChange={() => {
			  api.patch('receiptsChangePaymentStatus', { is_payed: !isPayed }, id)
			    .then(() => setIsPayed(!isPayed));
      }}
      checked={isPayed || false}
      classes={{
			  root: classes.root,
			  switchBase: classes.switchBase,
			  thumb: classes.thumb,
			  track: classes.track,
			  checked: classes.checked,
      }}
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
};
export default ReceiptsOverview;
