import React from 'react';
import CoProducerTable from './CoProducersTable';
import CoProducersCreateUpdateForm from './CoProducersCreateUpdateForm';

const CoProducers = () => {
  return (
    <>
      <CoProducerTable />
      <CoProducersCreateUpdateForm />
    </>
  );
};

export default CoProducers;
