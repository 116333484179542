const columns = {
  vaf: [
    {
      width: 70,
      label: 'Code',
      dataKey: 'code',
    },
    {
      width: 350,
      label: 'Description',
      dataKey: 'title',
    },
    {
      width: 100,
      label: 'BE Budget',
      dataKey: 'budget_be',
    },
    {
      width: 130,
      label: 'Total Flemisch',
      dataKey: 'total_flemish_costs',
    },
	  {
		  width: 110,
		  label: 'Difference',
		  dataKey: 'difference',
	  },
	  {
		  width: 160,
		  label: 'Total Non Flemisch',
		  dataKey: 'total_not_flemish_costs',
	  },
	  {
		  width: 220,
		  label: 'Total Flemish + Non Flemish',
		  dataKey: 'total_flemish_not_flemish',
	  },
	  {
		  width: 200,
		  label: 'Total Budget Flemisch',
		  dataKey: 'total_budget_flemish_costs',
	  },
	  {
		  width: 200,
		  label: 'Total Budget Non Flemisch',
		  dataKey: 'total_budget_not_flemish_costs',
	  },

  ],
  'screen-flanders': [
    {
      width: 70,
      label: 'Code',
      dataKey: 'code',
    },
    {
      width: 350,
      label: 'Description',
      dataKey: 'title',
    },
    {
      width: 150,
      label: 'Eligible FL',
      dataKey: 'cost_eligible',
    },
    {
      width: 150,
      label: 'Non-Eligible FL',
      dataKey: 'cost_non_eligible',
    }, {
      width: 150,
      label: 'Non Flemish',
      dataKey: 'non_flemish',
    },
  ],
  'tax-shelter': {
  	detail: [
		  {
			  width: 100,
			  label: 'Code',
			  dataKey: 'code',
		  },
		  {
			  width: 400,
			  label: 'Description',
			  dataKey: 'title',
		  },
		  {
			  width: 200,
			  label: 'BE Production',
			  dataKey: 'be_production_cost',
		  },
		  {
			  width: 200,
			  label: 'BE Non Production',
			  dataKey: 'be_non_production_cost',
		  },
		  {
			  width: 200,
			  label: 'Non BE',
			  dataKey: 'non_belgian_cost',
		  }, {
			  width: 200,
			  label: 'Total BE',
			  dataKey: 'total_belgium',
		  }, {
			  width: 200,
			  label: 'Co Producer 1',
			  dataKey: 'co_producers_0_total_cost_report',
		  }, {
			  width: 200,
			  label: 'Co Producer 2',
			  dataKey: 'co_producers_1_total_cost_report',
		  }, {
			  width: 200,
			  label: 'Remain BE',
			  dataKey: 'remain_be',
		  }, {
			  width: 200,
			  label: 'Remain Co Producer 1',
			  dataKey: 'co_producers_0_remain_budget',
		  }, {
			  width: 200,
			  label: 'Remain Co Producer 2',
			  dataKey: 'co_producers_1_remain_budget',
		  }, {
			  width: 200,
			  label: 'BE Budget',
			  dataKey: 'be_budget',
		  }, {
			  width: 200,
			  label: 'Co Producer 1 Budget',
			  dataKey: 'co_producers_0_budget',
		  }, {
			  width: 200,
			  label: 'Co Producer 2 Budget',
			  dataKey: 'co_producers_1_budget',
		  }, {
			  width: 200,
			  label: 'Total Budget Production',
			  dataKey: 'total_budget_production',
		  },
      {
        width: 200,
        label: 'Grand Total',
        dataKey: 'grand_total',
      },
	  ],
	  summary: [
		  {
			  width: 70,
			  label: 'Code',
			  dataKey: 'code',
		  },
		  {
			  width: 350,
			  label: 'Description',
			  dataKey: 'title',
		  },
		  {
			  width: 150,
			  label: 'Total',
			  dataKey: 'total_spend',
		  }, {
			  width: 150,
			  label: 'Total BE',
			  dataKey: 'total_be',
		  }, {
			  width: 160,
			  label: 'Total Co Producer 1',
			  dataKey: 'co_producers_0_total',
		  }, {
			  width: 160,
			  label: 'Total Co Producer 2',
			  dataKey: 'co_producers_1_total',
		  },
	  ],
	  numbers: [
		  {
			  width: 150,
			  label: 'Date',
			  dataKey: 'date',
		  }, {
			  width: 150,
			  label: 'PO Number',
			  dataKey: 'po',
		  }, {
			  width: 200,
			  label: 'Address',
			  dataKey: 'companyAddress',
		  }, {
			  width: 130,
			  label: 'Invoice Number',
			  dataKey: 'invoice',
		  }, {
			  width: 200,
			  label: 'Company',
			  dataKey: 'companyName',
		  }, {
			  width: 120,
			  label: 'Amount Invoice',
			  dataKey: 'amount',
		  },
	  ]
  }
};

export default columns;
