import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import { useRowStyles } from '../../../Projects/ProjectsOverview/style';

const SetSwitcher = (props) => {
  // eslint-disable-next-line camelcase
  const { onChange, rowData: { is_confirmed } } = props;
  const classes = useRowStyles();
  const [state, setState] = useState(false);
  useEffect(() => {
    setState(is_confirmed);
  }, []);
  return (
    <Tooltip
      title="Confirmation status"
      aria-label="add"
    >
      <Switch
        onChange={() => {
				  setState(!state);
				  onChange(!state);
        }}
        checked={state}
        classes={{
				  root: classes.root,
				  switchBase: classes.switchBase,
				  track: classes.track,
				  checked: classes.checked,
        }}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </Tooltip>
  );
};
SetSwitcher.propTypes = {
  onChange: PropTypes.func.isRequired,
  rowData: PropTypes.shape({
    is_confirmed: PropTypes.bool.isRequired
  }).isRequired
};

export default SetSwitcher;
