import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import matchSorter from 'match-sorter';
import Box from '@material-ui/core/Box';
import CollapsibleContainer from '../../../components/CollapsibleContainer';
import api from '../../../instruments/api';
import ButtonCustom from '../../../components/Button';
import Input from '../../../components/Input';
import AddNewCoProducer from './AddNewCoProducer/AddNewCoProducer';

const BudgetData = (props) => {
  const { budget, onChange } = props;
  const [codes, setCodes] = useState([]);
  useEffect(() => {
    api.get('codes').then((res) => {
      const { results } = res;
      const result = results.reduce((acc, current) => {
        const { children } = current;
        const array = children.map((item) => ({ ...item, categoryTitle: current.title }));
        return [...acc, ...array];
      }, []);
      setCodes(result);
    });
  }, []);

  const coProducer = budget.co_producers_budget ? budget.co_producers_budget.map((item) => {
    return (
      <CollapsibleContainer
        key={item.alt_name}
        countryName={`${item.name} Budget`}
        amount={item.total_amount}
      >
        <div style={{ marginBottom: 20 }}>
          <ButtonCustom
            onClick={async () => {
              const data = {
                co_producer_id: item.id,
              };
              await api.attachCoProducerProject(data,
                props.match.params.id, 'detach').then(() => {
                onChange();
              });
            }}
          >
            - Remove Co-producer
          </ButtonCustom>
        </div>
        <MaterialTableDemo
          {...item}
          onChange={onChange}
          codes={codes}
          projectId={props.match.params.id}
          is_cost_report
          data={item.co_producers_budgets.map((itemInner) => ({ ...itemInner, countryId: item.id }))}
        />
        <SetNewBudgetCode
          {...props}
          isCostReport
          countryId={item.id}
          coProducerId={item.id}
          codes={codes}
        />
      </CollapsibleContainer>
    );
  }) : [];

  return (
    <>
      {budget.main_country_budget && (
      <CollapsibleContainer
        countryName={`${budget.main_country_budget.alt_name} Budget`}
        amount={budget.main_country_budget.total_amount}
      >
        <MaterialTableDemo
          {...budget.main_country_budget}
          onChange={onChange}
          codes={codes}
          projectId={props.match.params.id}

          data={budget.main_country_budget.project_budgets.map((itemInner) => ({ ...itemInner, countryId: budget.main_country_budget.id }))}
        />
        <SetNewBudgetCode {...props} isCostReport={budget.main_country_budget.is_cost_report} countryId={budget.main_country_budget.id} codes={codes} />
      </CollapsibleContainer>
      )}
      {coProducer}
      <AddNewCoProducer {...props} />
    </>
  );
};

export default BudgetData;

export function MaterialTableDemo(props) {
  const {
    codes, data, projectId, onChange, is_cost_report,
  } = props;
  const [dataSet, setData] = useState([]);
  useEffect(() => {
    setData(data.map((item) => ({ ...item, is_cost_report })));
  }, [data]);
  const columns = [
    {
      title: 'Code',
      field: 'code',
      width: 5,
      render: (props) => {
        return <p style={{ margin: 0 }}>{props.code.code.toFixed(2)}</p>;
      },
      editComponent: (props) => (<CodesEdit {...props} codes={codes} data={data} />),
    },
    {
      title: 'Description',
      field: 'description',
      render: (props) => {
        return <p style={{ margin: 0 }}>{props.code.title}</p>;
      },
      editComponent: () => (
        <>
        </>
      )
    },
    {
      title: 'Amount',
      field: 'amount',
      width: 5,
      render: (props) => (
        <p style={{ margin: 0 }}>{props.code.parent_id ? `€${props.amount.toFixed(2)}` : ''}</p>
      )
    },
  ];
  return (
    <MaterialTable
      components={{
			  Toolbar: (props) => (
  <div style={{ padding: 0, display: 'none' }}>
    <MTableToolbar {...props} />
  </div>
			  )
      }}
      title=" "
      options={{
			  search: false,
			  paging: false,
			  actionsColumnIndex: -1,
      }}
      columns={[...columns, (is_cost_report ? {
	      title: 'Cost report',
	      field: 'cost_report',
	      width: 6,
	      render: (props) => (
  <p style={{ margin: 0 }}>{props.code.parent_id ? `${props.cost_report.toFixed(2)}` : ''}</p>
	      )
      } : {})]}
      data={dataSet}
      editable={{
			  isEditHidden: (rowData) => !rowData.code.parent_id,
			  isDeleteHidden: (rowData) => !rowData.code.parent_id,
			  onRowUpdate: (newData) => new Promise((resolve) => {
          let requestData = {};
          if (newData.countryId == 1) {
            requestData = {
              country_id: newData.countryId,
              code_id: newData.code.id,
              amount: newData.amount,
              cost_report: newData.cost_report || 0,
            };
          } else {
            requestData = {
              co_producer_id: newData.co_producer_id,
              code_id: newData.code.id,
              amount: newData.amount,
              cost_report: newData.cost_report || 0,
            };
          }

			    api.putWidthTwoParams('projectsBudget',
				    {
					    ...requestData
				    }, projectId, newData.id).then(() => {
			      onChange();
			      resolve();
			    });
			  }),
			  onRowDelete: (data) => new Promise((resolve) => {
			    api.deleteWidthTwoParams('projectsBudget', projectId, data.id).then(() => {
			      onChange();
			      resolve();
			    });
			  }),
      }}
    />
  );
}

export const CodesEdit = ({ codes, data, ...props }) => {
  const [autoComplete, setAutoComplete] = useState({});
  useEffect(() => {
    setAutoComplete(codes.find((item) => props.rowData.code.id === item.id));
  }, []);
  useEffect(() => {
    console.log(autoComplete);
  }, [autoComplete]);
  return (
    <Autocomplete
      onChange={(_, value) => {
			  props.onChange(value);
			  setAutoComplete(value);
			  // stateSetter(value, setSelectedCode);
      }}
      style={{ width: 250, padding: 0 }}
      options={codes}
      size="small"
      value={autoComplete}
      groupBy={(option) => option.categoryTitle}
      getOptionLabel={(option) => (option.title && option.code
			  ? `${option.code} — ${option.title}` : option.code)}
      filterOptions={(options, { inputValue }) => {
			  return matchSorter(options, inputValue, { keys: ['code', 'title'] });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Code"
          variant="outlined"
        />
      )}
    />
  );
};

export const SetNewBudgetCode = (props) => {
  const {
    onChange, codes, match, countryId, isCostReport, coProducerId
  } = props;
  const [newCodes, setNewCodes] = useState({});
  const [newAmount, setNewAmount] = useState('');
  const [addButtonState, setButtonState] = useState(false);
  const [codesError, setCodesError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [newCostReport, setNewCostReport] = useState('');

  const bodyCoproducerOrCountry = coProducerId ? {
    code_id: newCodes ? newCodes.id : '',
    amount: parseInt(newAmount, 10),
    co_producer_id: coProducerId,
    cost_report: parseInt(newCostReport, 10) || 0,
  } : {
    code_id: newCodes ? newCodes.id : '',
    amount: parseInt(newAmount, 10),
    country_id: countryId,
    cost_report: parseInt(newCostReport, 10) || 0,
  };
  return (
    <Box mt={1}>
      {!addButtonState && (
      <ButtonCustom
        onClick={() => {
					  setButtonState(true);
        }}
      >
        + Add new Line
      </ButtonCustom>
      )}
      {addButtonState && (
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ButtonCustom onClick={() => {
					  let error = false;
					  if (!newAmount) {
					    setAmountError(true);
					    error = true;
					  } else {
					    setAmountError(false);
					  }
					  if (!Object.keys(newCodes).length) {
					    setCodesError(true);
					    error = true;
					  } else {
					    setCodesError(false);
					  }
					  if (error) {
					    return;
					  }
					  api.post('projectsBudget',
            bodyCoproducerOrCountry, match.params.id).then(() => {
					    setButtonState(false);
					    onChange();
					    setNewAmount('');
					    setNewCodes({});
					    setCodesError(false);
					    setAmountError(false);
						  setNewCostReport('');
					  });
        }}
        >
          Add
        </ButtonCustom>
        <ButtonCustom
          type="primary"
          onClick={() => {
							  setButtonState(false);
							  onChange();
							  setNewAmount('');
							  setNewCodes({});
							  setCodesError(false);
							  setAmountError(false);
	          setNewCostReport('');
          }}
        >
          Cancel
        </ButtonCustom>
        <Autocomplete
          onChange={(_, value) => {
						  setNewCodes(value);
          }}
          style={{ width: (!isCostReport ? 350 : 300), padding: 0 }}
          options={codes}
          size="small"
          value={newCodes}
          groupBy={(option) => option.categoryTitle}
          getOptionLabel={(option) => (option.title && option.code
						  ? `${option.code} — ${option.title}` : option.code)}
          filterOptions={(options, { inputValue }) => {
						  return matchSorter(options, inputValue, { keys: ['code', 'title'] });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Code"
              error={codesError}
              helperText={codesError ? 'Codes can\'t be empty' : ''}
              variant="outlined"
            />
          )}
        />
        <Box style={{ width: (!isCostReport ? 200 : 150) }}>
          <Input
            error={amountError}
            label="Amount"
            helperText="Amount field can't be empty"
            value={newAmount}
            onChange={({ target: { value } }) => {
							  if (/^[0-9]*\.?[0-9]*$/.test(value)) {
							    setNewAmount(value);
							  }
            }}
          />
        </Box>
        {isCostReport
	      && (
<Box style={{ width: (!isCostReport ? 200 : 150) }}>
  <Input
    label="Cost Report"
    helperText="Cost Report field can't be empty"
    value={newCostReport}
    onChange={({ target: { value } }) => {
							  if (/^[0-9]*\.?[0-9]*$/.test(value)) {
							    setNewCostReport(value);
							  }
    }}
  />
</Box>
	      )}
      </Box>
      )}
    </Box>
  );
};
