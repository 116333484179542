import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Input from "../../../../components/Input";

const SetCountry = (props) => {
  const { companies, rowData: { company, company_str }, onChange, is_cost_report } = props;
  
  const [state, setState] = useState(is_cost_report ? '' : {});
  useEffect(() => {
    setState(!is_cost_report ? company : company_str);
  }, []);
  return (
  	<>
	  {is_cost_report ?
			  <Input
				  label="Company"
				  value={state}
				  onChange={({target: {value}}) => {
					  setState(value);
					  onChange(value);
				  }}
			  /> :
    <Autocomplete
      onChange={(_, value) => {
			  setState(value);
			  onChange(value);
      }}
      size="small"
      options={companies}
      getOptionLabel={(option) => option.name}
      style={{ width: 200 }}
      value={state}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Company"
          variant="outlined"
        />
      )}
    />
}
</>
  );
};
SetCountry.propTypes = {
  onChange: PropTypes.func.isRequired,
  rowData: PropTypes.shape({
	  company: PropTypes.shape({
		  address: PropTypes.string.isRequired,
		  id: PropTypes.number.isRequired,
		  is_active: PropTypes.bool.isRequired,
		  name: PropTypes.string.isRequired,
		  region_id: PropTypes.number,
		  type: PropTypes.string.isRequired,
		  vat_number: PropTypes.string.isRequired,
	  }),
	  company_str: PropTypes.string,
  }).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      region_id: PropTypes.number,
      is_active: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
      vat_number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SetCountry;
