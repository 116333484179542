import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './style';
import CollapsibleTable from '../../../components/MUICollapsibleTable';
import api from '../../../instruments/api';
import ButtonCustom from '../../../components/Button';
import { ContextApp } from '../../../contextStore/reducer';
import { actions } from '../../../contextStore/actions';
import DeleteItemPopup from '../../ReceiptsList/DeleteItemPopup';

const CompaniesOverview = () => {
  const style = styles();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const { dispatch, state } = useContext(ContextApp);
  const { selectedItem, deletedItem, isDeleteCompany } = state;
  const getCompanies = () => {
  	const perPageNew = Math.floor((window.innerHeight - 270) / 75);
	  api.get('companies', { page, perPage: perPageNew, type: 'NO_CO_PRODUCER' }).then((response) => {
		  const { results, total } = response;
		  setTotal(total);
		  setData(results);
	  });
  };
  const deleteCompany = (id) => {
    api.delete('companies', id)
      .then(() => getCompanies());
  };
  useEffect(() => {
    if (!Object.keys(selectedItem).length) {
      getCompanies();
    }
  }, [selectedItem]);
  useEffect(() => {
	  getCompanies();
  }, [page]);

  function openPopup() {
    dispatch(actions.setPopupState());
  }

  return (
    <Box>
      <Paper>
        <Box className={style.rootDashboardContainer} p={5}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" gutterBottom className={style.dashboardTitle}>
              Companies
            </Typography>
            <ButtonCustom onClick={() => {
						  openPopup();
            }}
            >
              Add company
            </ButtonCustom>
          </Box>
          <CollapsibleTable data={data} />
          <Box mt={4}>
            <Pagination
              style={{
                transform: 'translate(-50%)',
				        marginLeft: '50%',
				        width: 350,
				        display: 'flex',
				        justifyContent: 'center',
			        }}
              defaultPage={total + 1}
              count={Math.ceil(total / Math.floor((window.innerHeight - 270) / 75))}
              onChange={(event, value) => {
				        setPage(value - 1);
			        }}
            />
          </Box>
        </Box>
      </Paper>
      <DeleteItemPopup
        handleClose={() => dispatch(actions.openCloseDeleteCompanyPopup())}
        callback={() => {
          dispatch(actions.openCloseDeleteCompanyPopup());
          deleteCompany(deletedItem.id);
        }}
        open={isDeleteCompany}
        showDeleteButton={deletedItem.isDeletable}
      >
        <DialogContent>
          {deletedItem.isDeletable ? 'Are you sure you want to delete a company' : 'You can\'t delete the company, because there are connected invoices.'}
        </DialogContent>
      </DeleteItemPopup>
    </Box>
  );
};
export default CompaniesOverview;
