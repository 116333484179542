import './App.css';
import React, { useEffect, useReducer } from 'react';
import RouterInner from './Router';
import { initialState, reducer, ContextApp } from './contextStore/reducer';

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
  	if (!localStorage.getItem('isLoggedIn') && window.location.pathname !== '/login') {
  		window.location.href = '/login';
	  }
  }, []);
  return (
      <ContextApp.Provider value={{ dispatch, state }}>
        <div style={{ background: '#eeeeee', }} className="App">
          <RouterInner />
        </div>
      </ContextApp.Provider>
  );
}
/*"proxy": "https://fb.selectotech.com",
*   "proxy": "http://localhost:3000",*/
export default App;
