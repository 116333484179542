import React from 'react';
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Codes from '../views/Codes';
import Companies from '../views/Companies';
import Projects from '../views/Projects';
import BillForm from '../views/BillForm';
import ReceiptsList from '../views/ReceiptsList';
import Budget from '../views/Budget';
import ReportDetailBudget from '../views/ReportDetailBudget';
import ReportReceipts from '../views/ReportDetailBudget/Receipts';
import Overhead from '../views/Overhead';
import CoProducers from '../views/CoProducers';
import Countries from '../views/Countries';

const RouterInner = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/login"
          render={(props) => {
					  if (localStorage.getItem('isLoggedIn')) {
					    window.location.href = '/';
					    return true;
					  }
					  return (
  <Login {...props} />
					  );
          }}
        />
        <Route
          path="/"
          render={(props) => {
					  if (!localStorage.getItem('isLoggedIn')) {
					    window.location.href = '/login';
					    return true;
					  }
					  return (
  <Dashboard {...props}>
    <Route exact path="/codes" render={(propsInner) => <Codes {...propsInner} />} />
    <Route exact path="/countries" render={(propsInner) => <Countries {...propsInner} />} />
    <Route exact path="/companies" render={(propsInner) => <Companies {...propsInner} />} />
    <Route exact path="/coproducer" render={(propsInner) => <CoProducers {...propsInner} />} />
    <Route exact path="/projects" render={(propsInner) => <Projects {...propsInner} />} />
    <Route exact path="/overhead" render={(propsInner) => <Overhead {...propsInner} />} />
    <Route path="/receipts/add/:id?" render={(propsInner) => <BillForm {...propsInner} />} />
    <Route path="/receipts/edit/:id?" render={(propsInner) => <BillForm {...propsInner} />} />
    <Route exact path="/receipts" render={(propsInner) => <ReceiptsList {...propsInner} />} />
    <Route exact path="/projects/budget/:id" render={(propsInner) => <Budget tab={0} {...propsInner} />} />
    <Route exact path="/projects/financing/:id" render={(propsInner) => <Budget tab={1} {...propsInner} />} />
    <Route exact path="/projects/report/:id" render={(propsInner) => <Budget tab={2} {...propsInner} />} />
    <Route
      exact
      path="/projects/report/:id/vaf"
      render={(propsInner) => <ReportDetailBudget {...propsInner} type="vaf" />}
    />
    <Route
      exact
      path="/projects/report/:id/screen-flanders"
      render={(propsInner) => <ReportDetailBudget {...propsInner} type="screen-flanders" />}
    />
    <Route
      exact
      path="/projects/report/:id/tax-shelter/detail-budget"
      render={(propsInner) => <ReportDetailBudget {...propsInner} type="tax-shelter" subType="detail" />}
    />
    <Route
      exact
      path="/projects/report/:id/tax-shelter/summary"
      render={(propsInner) => <ReportDetailBudget {...propsInner} type="tax-shelter" subType="summary" />}
    />
    <Route
      exact
      path="/projects/report/:id/tax-shelter/po-numbers"
      render={(propsInner) => <ReportDetailBudget {...propsInner} type="tax-shelter" subType="numbers" />}
    />
    <Route
      exact
      path="/projects/report/:id/receipts/:codeId"
      render={(propsInner) => <ReportReceipts {...propsInner} />}
    />
  </Dashboard>
					  );
          }}
        />
        <Redirect to="/login" />
      </Switch>
    </BrowserRouter>
  );
};
export default RouterInner;
