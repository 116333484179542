import React from 'react';
import clsx from 'clsx';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import useStyles from './style';


export default function CollapsibleContainer(props) {
  const { children, countryName, amount } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <CardActions
        disableSpacing
        style={{
          paddingBottom: 0,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box style={{
        	display: 'flex',
	        justifyContent: 'space-between',
	        alignItems: 'center'
        }}
        >
          <IconButton
            className={clsx(classes.expand, {
					  [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
          <Typography component="h2">
            {countryName}
          </Typography>
        </Box>
        <Typography component="h2">
          €
          {amount.toFixed(2)}
        </Typography>
      </CardActions>
      <Box style={{
	    	width: '100%',
		    height: 3,
		    backgroundColor: '#aa4e9b',
	    }}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {children}
        </CardContent>
      </Collapse>
    </>
  );
}
