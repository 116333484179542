import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import api from '../../instruments/api';
import { actions } from '../../contextStore/actions';
import { ContextApp } from '../../contextStore/reducer';
import useRowStyles from './style';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [status, setStatus] = useState(false);
  const { dispatch } = useContext(ContextApp);
  useEffect(() => {
    setStatus(row.is_active);
  }, []);

  async function changeStatus() {
    api.patch('companiesChangeStatus', { is_active: !status }, row.id).then((res) => {
      setStatus(res.is_active);
    });
  }

  const makeActionButton = () => {
    return (
      <>
        <Tooltip title="Edit code" aria-label="add">
          <EditIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(actions.setPopupState());
              dispatch(actions.selectItem(row));
            }}
          />
        </Tooltip>
        <Tooltip title="Delete company" aria-label="add">
          <DeleteIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(actions.openCloseDeleteCompanyPopup());
              dispatch(actions.setDeletedCompany(row));
            }}
          />
        </Tooltip>
      </>
    );
  };
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>{row.name || ''}</TableCell>
        <TableCell>{row.country.name || ''}</TableCell>
        <TableCell>{row.address || ''}</TableCell>
        <TableCell>{row.vat_number}</TableCell>
        <TableCell component="th" scope="row">
          {row.type || ''}
        </TableCell>
        <TableCell>
          {row.country.is_active || ''}
          <Switch
            onChange={changeStatus}
            checked={status || false}
            classes={{
						  root: classes.root,
						  switchBase: classes.switchBase,
						  thumb: classes.thumb,
						  track: classes.track,
						  checked: classes.checked,
            }}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </TableCell>
        <TableCell>{makeActionButton()}</TableCell>
        <TableCell>
          {
						!!row.contacts.length
						&& (
							<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						)
					}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                style={{
								  fontWeight: 500,
								  lineHeight: '1.5rem',
                }}
                gutterBottom
                component="div"
              >
                Contacts
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row.contacts.map((contact) => (
                    <TableRow className={classes.row} key={`${contact.note}${contact.name}`}>
                      <TableCell>{contact.note}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    is_active: PropTypes.bool,
    id: PropTypes.number,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
    vat_number: PropTypes.string,
    country: PropTypes.object.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.shape({
      address: PropTypes.string,
      note: PropTypes.string.isRequired,
    }))
  }).isRequired
};

function CollapsibleTable(props) {
  const { data } = props;
  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>VAT number</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CollapsibleTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
    vat_number: PropTypes.string,
    country: PropTypes.object.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.shape({
      address: PropTypes.string,
      note: PropTypes.string.isRequired,
    }))
  })).isRequired
};
export default CollapsibleTable;
