import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CollapsibleContainer from '../../../components/CollapsibleContainer';
import api from '../../../instruments/api';
import SetFinance from './SetFinance';
import FinanceTable from './FinanceTable';
import AddNewCountry from './AddNewCountry/AddNewCountry';
import ButtonCustom from '../../../components/Button';

const FinancingData = (props) => {
  const { financing, onChange } = props;
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    api.get('companies', { type: 'FUND', perPage: 500 }).then((res) => {
      const { results } = res;
      const filteredCountries = results.filter((item) => item.type.toLowerCase() === 'fund');
	    setCompanies(filteredCountries);
    });
  }, []);
  const containers = financing.map((item) => {
    return (
      <CollapsibleContainer
        key={item.name}
        countryName={`${item.alt_name || item.name} Financing`}
        amount={item.total_amount}
      >
        {item.id !== 1 && (
        <div style={{ marginBottom: 20 }}>
          <ButtonCustom
            onClick={async () => {
              const data = {
                country_id: item.id,
              };
              await api.attachCountryProject(data,
                props.match.params.id, 'detach').then(() => {
                onChange();
              });
            }}
          >
            - Remove Country
          </ButtonCustom>
        </div>
        )}
        <FinanceTable
          {...item}
          onChange={onChange}
          companies={companies}
          projectId={props.match.params.id}
          data={item.project_financing.map((itemInner) => ({ ...itemInner, countryId: item.id }))}
        />
        {/* eslint-disable-next-line max-len */}
        <SetFinance {...props} country_id={item.id} companies={companies} />
      </CollapsibleContainer>
    );
  });
  return (
    <>
      {containers}
      <AddNewCountry projectId={props.match.params.id} onChange={onChange} />
    </>
  );
};
FinancingData.propTypes = {
  onChange: PropTypes.func.isRequired,
  financing: PropTypes.arrayOf(
  	PropTypes.shape({
		  alt_name: PropTypes.string.isRequired,
  		id: PropTypes.number.isRequired,
		  total_amount: PropTypes.string.isRequired,
  		is_active: PropTypes.bool.isRequired,
		  name: PropTypes.string.isRequired,
		  is_cost_report: PropTypes.bool.isRequired,
  	}),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};
export default FinancingData;
