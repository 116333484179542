import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Switch from '@material-ui/core/Switch';
import api from '../../../instruments/api';
import ReactVirtualizedTable from '../../../components/MUITable';
import useRowStyles from '../../../components/MUICollapsibleTable/style';
import styles from '../../Budget/style';

const switcher = (status, classes) => {
  return (
    <Switch
      disabled
      onChange={() => {}}
      checked={status || false}
      classes={{
			  root: classes.root,
			  switchBase: classes.switchBase,
			  thumb: classes.thumb,
			  track: classes.track,
			  checked: classes.checked,
      }}
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
};
const InvoiceLink = (invoice,receiptId,pushUri) => {
	return (
		<span style={{
			textDecoration: 'underline',
			cursor: 'pointer',
			color: '#854376'
		}}
		   onClick={() => {
			   pushUri(`/receipts/edit/${receiptId}`)
		   }}
		>
			{invoice}
		</span>
	);
};
const ReportReceipts = (props) => {
  const classes = useRowStyles();
  const style = styles();
  const [data, setData] = useState([]);
  const { type, match, subType } = props;
  const [projectName, setProjectName] = useState('');
  const [receiptCodeData, setReceiptCodeData] = useState({});
  useEffect(() => {
	  api.getById('projects', match.params.id).then((res) => {
		  setProjectName(res.name || '');
	  });
	  api.get('receipts', {
		  project_id: match.params.id,
		  code_id: match.params.codeId,
	  })
		  .then((res) => {
		  	const { results } = res;

		  	if (results.length && results[0].code){
				setReceiptCodeData(results[0].code);
			}

			  setData(results.map((item) => ({
				  ...item,
				  date: item.date.substring(0, 10),
				  amount:item.amount?.toFixed(2),
				  companyName: item.company.name,
				  is_payed: switcher(item.is_payed, classes),
				  invoice: InvoiceLink(item.invoice,item.id,(t)=>props.history.push(t))
			  })));
		  });
  }, []);

	  return (
  <>
    <Box>
      <Paper>
        <Box
          className={style.rootDashboardContainer}
          p={5}
        >
          <Typography
            onClick={() => {
								  props.history.push(window.previousLocation || '/projects');
							  }}
            style={{
								  textAlign: 'left', color: '#999898', marginBottom: 10, cursor: 'pointer'
							  }}
          >
            <PlayArrowIcon style={{ transform: 'rotate(180deg)', position: 'relative', top: 7 }} />
	          {projectName}
            {' '}
            {
	            window.previousLocation
	          	? window.previousLocation.includes('vaf')
	          	  ? 'VAF '
			          : window.previousLocation.includes('screen-flanders')
				          ? 'Screen Flanders'
				          : window.previousLocation.includes('summary')
					          ? 'Tax Shelter Summary'
					          : window.previousLocation.includes('po-numbers')
						          ? 'Tax Shelter PO numbers'
						          : 'Tax Shelter Detail Budget'
		            : 'Report'
	          }
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              className={style.dashboardTitle}
              style={{ marginBottom: 0, fontSize: 25 }}
            >
				{receiptCodeData.code?.toFixed(2)} {receiptCodeData.title}
            </Typography>
            <Box ml={1} style={{ display: 'none' }}>
              <IconButton size="small">
                <PrintOutlinedIcon style={{ color: 'black' }} />
              </IconButton>
              <IconButton size="small">
                <GetAppOutlinedIcon style={{ color: 'black' }} />
              </IconButton>
            </Box>
          </Box>
          <Typography
            className={style.inputTypography}
            style={{ marginBottom: 15, fontSize: 15, fontWeight: 600 }}
          />
          <Box style={{
							  overflow: 'hidden',
							  maxWidth: 900,
						  }}
          >
            <Box style={{ width: (type === 'tax-shelter' && subType === 'detail' ? 1900 : 900) }}>
              <ReactVirtualizedTable
                columns={[
										  {
											  width: 150,
											  label: 'Invoice Date',
											  dataKey: 'date',
										  }, {
                								width: 200,
												label: 'PO',
												dataKey: 'po',
										  }, {
											  width: 200,
											  label: 'Invoice',
											  dataKey: 'invoice',
										  }, {
											  width: 250,
											  label: 'Company',
											  dataKey: 'companyName',
										  }, {
											  width: 200,
											  label: 'Amount',
											  dataKey: 'amount',
										  }, {
											  width: 150,
											  label: 'Payment Status',
											  dataKey: 'is_payed',
										  },
									  ]}
                data={data}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  </>
	  );
};

export default ReportReceipts;
