import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import styles, { useRowStyles } from './style';
import ReactVirtualizedTable from '../../../components/MUITable';
import api from '../../../instruments/api';
import { ContextApp } from '../../../contextStore/reducer';
import { actions } from '../../../contextStore/actions';
import ButtonCustom from '../../../components/Button';

export const MakeStatusSwitcher = (item) => {
  const classes = useRowStyles();
  const [innerItem, setItem] = useState({});
  useEffect(() => {
    setItem(item);
  }, []);

  function setNewStatus() {
    api.patch('projectsChangeStatus', { is_active: !innerItem.is_active }, innerItem.id).then((response) => {
      setItem(response);
    });
  }

  return (
    <Tooltip title="Edit code" aria-label="add">
      <Switch
        onChange={() => {
				  setNewStatus();
        }}
        checked={innerItem.is_active || false}
        classes={{
				  root: classes.root,
				  switchBase: classes.switchBase,
				  thumb: classes.thumb,
				  track: classes.track,
				  checked: classes.checked,
        }}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </Tooltip>
  );
};
const ProjectsOverview = (props) => {
  const { dispatch, state } = useContext(ContextApp);
  const { selectedCode, createdCode, selectedItem } = state;

  const style = styles();

  const [tableData, setTableData] = useState([]);
  const makeInnerBtn = (item) => {
    return (
      <Tooltip title="Edit code" aria-label="add">
        <EditIcon
          style={{ cursor: 'pointer' }}
          onClick={() => {
					  dispatch(actions.selectItem(item));
					  dispatch(actions.setPopupState());
          }}
        />
      </Tooltip>
    );
  };
  const setSwitcher = (item) => {
    return (<MakeStatusSwitcher {...item} />);
  };
  const setName = (item) => {
    return (
      <p style={{
    		textDecoration: 'underline',
		    cursor: 'pointer',
	      color: '#854376'
	    }}
         onClick={() => {
         	props.history.push(`/projects/budget/${item.id}`)
         }}
      >
        {item.name}
      </p>
    );
  };
  const getData = () => {
    api.get('projects').then((res) => {
      const { results } = res;
      const data = results.map((current) => {
        const {
          // eslint-disable-next-line camelcase
          name, id, started_at,
        } = current;
        // eslint-disable-next-line camelcase
        //let date = new Date(started_at * 1000);
        let date = started_at.substring(0, 10);
        return {
          id,
          name: setName(current),
          date,
          budget: current.total_budget.toFixed(2),
          beBudget: current.total_budget_be.toFixed(2),
          beSpent: current.be_spent.toFixed(2),
          actions: makeInnerBtn(current),
          status: setSwitcher(current),
        };
      });
      setTableData(data);
    });
  };
  useEffect(() => {
    if (!Object.keys(selectedItem).length) {
      getData();
    }
  }, [selectedItem]);

  useEffect(() => {
    if (Object.keys(createdCode).length) {
      const newArray = [...tableData, {
        ...createdCode,
        action: makeInnerBtn({
          id: createdCode.id,
          code: createdCode.code,
          title: createdCode.title
        })
      }];
      setTableData(newArray);
      dispatch(actions.setCreatedCodeToList({}));
    }
  }, [createdCode]);
  useEffect(() => {
    const copyOfDataArray = [...tableData];
    if (Object.keys(selectedCode).length && selectedCode.isChanged) {
      const changedItemIndex = tableData.findIndex((item) => item.id === selectedCode.id);
      delete selectedCode.isChanged;
      selectedCode.action = makeInnerBtn({
        id: selectedCode.id,
        code: selectedCode.code,
        title: selectedCode.title
      });
      copyOfDataArray.splice(changedItemIndex, 1, selectedCode);
      setTableData(copyOfDataArray);
    }
  }, [selectedCode]);
  const TABLE_COLUMNS = [
    {
      width: 50,
      label: 'Nr.',
      dataKey: 'id',
    },
    {
      width: 180,
      label: 'Project',
      dataKey: 'name',
    },
    {
      width: 150,
      label: 'Start date',
      dataKey: 'date',
    },
    {
      width: 130,
      label: 'Total budget',
      dataKey: 'budget',
    },
    {
      width: 100,
      label: 'BE budget',
      dataKey: 'beBudget',
    },
    {
      width: 100,
      label: 'BE spent',
      dataKey: 'beSpent',
    },
    {
      width: 100,
      label: 'Status',
      dataKey: 'status',
    },
    {
      width: 100,
      label: 'Actions',
      dataKey: 'actions',
    },
  ];
  useEffect(() => {
    getData();
  }, []);

  function openPopup() {
    dispatch(actions.setPopupState());
  }

  return (
    <Box>
      <Paper>
        <Box className={style.rootDashboardContainer} p={5}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" gutterBottom className={style.dashboardTitle}>
              Projects overview
            </Typography>
            <ButtonCustom onClick={() => {
						  openPopup();
            }}
            >
              Add project
            </ButtonCustom>
          </Box>
          <ReactVirtualizedTable columns={TABLE_COLUMNS} data={tableData} />
        </Box>
      </Paper>
    </Box>
  );
};
export default ProjectsOverview;
