import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import api from '../../../instruments/api';
import Input from '../../../components/Input';
import ButtonCustom from '../../../components/Button';
import Toast from '../../../components/Toast';
import { ContextApp } from '../../../contextStore/reducer';
import { actions } from '../../../contextStore/actions';


export default function ProjectsCreatePopup() {
  const { state, dispatch } = useContext(ContextApp);
  const { isPopupOpened, selectedItem } = state;


  const [toastOpened, closeToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');


  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));

  const [id, setId] = useState('');
  const clearState = () => {
  	setTimeout(() => {
      setId('');
      setName('');
      setDate(new Date().toISOString().substring(0, 10));
      setNameError(false);
	  }, 500);
  };
  useEffect(() => {
    if (Object.keys(selectedItem).length) {
      const {
	      // eslint-disable-next-line no-shadow,camelcase
        id, name, started_at
      } = selectedItem;
	    // eslint-disable-next-line camelcase
      setDate(started_at?started_at.substring(0,10):null);//new Date(started_at * 1000).toISOString().substring(0, 10));
      setId(id);
      setName(name);
    } else {
      clearState();
    }
  }, [selectedItem]);

  const handleClose = () => {
    dispatch(actions.selectItem({}));
    dispatch(actions.setPopupState());
    clearState();
  };

  const setupToast = (message, typeOfToast) => {
    setToastMessage(message);
    setToastType(typeOfToast);
  };
  const setCompany = () => {
    let error = false;
    if (!name) {
      error = true;
      setNameError(true);
    } else {
      setNameError(false);
    }
    if (error) {
      return;
    }
    if (!id) {
	    setupToast('You successfully set project', 'success');
	    api.post('projects', {
        name,
        started_at: date,
      }, id).then((res) => {
        if (res && res.id) {
          dispatch(actions.setPopupState());
          closeToast(true);
          clearState();
          dispatch(actions.selectItem({}));
        } else if (res && res.error && res.error.message === 'Validation Exception') {
          setupToast(res.error.details[0].message, 'error');
	        setTimeout(() => {
		        closeToast(true);
	        }, 200);
        }
      });
    } else {
	    setupToast('You successfully edit project', 'success');
	    api.put('projects', {
        name,
        started_at: date,
      }, id).then((res) => {
        if (res && res.id) {
          closeToast(true);
          dispatch(actions.setPopupState());
          clearState();
          dispatch(actions.selectItem({}));
        } else if (res && res.error && res.error.message === 'Validation Exception') {
          setupToast(res.error.details[0].message, 'error');
	        setTimeout(() => {
		        closeToast(true);
	        }, 200);
        }
      });
    }
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isPopupOpened}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{id ? 'Edit project' : 'Add project' }</DialogTitle>
        <DialogContent>
          <Box style={{ marginTop: 20 }}>
            <Input
              value={name}
              helperText="name can't be empty"
              error={nameError}
              onChange={({ target: { value } }) => setName(value)}
              label="Name"
            />
          </Box>
          <Box style={{ marginTop: 20 }}>
            <TextField
              id="date"
              style={{ width: '100%' }}
              value={date || new Date().toISOString().substring(0, 10)}
              onChange={({ target }) => {
							  setDate(target.value);
              }}
              label="Date"
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{
				  display: 'flex', justifyContent: 'center', marginBottom: 20
        }}
        >
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <ButtonCustom onClick={setCompany}>
            {id ? 'Edit project' : 'Add project' }
          </ButtonCustom>
        </DialogActions>
      </Dialog>
      <Toast
        message={toastMessage}
        type={toastType}
        open={toastOpened}
        closeToast={() => {
				  closeToast(false);
        }}
      />
    </>
  );
}
