import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import ButtonCustom from '../../../../components/Button';
import api from '../../../../instruments/api';
import Input from '../../../../components/Input';

const AddNewCountry = (props) => {
  const {
    onChange, match, projectId,
    isCostReport
  } = props;
  const [selectedData, setSelectedData] = useState({});
  const [companyDataError, setCompanyDataError] = useState(false);
  const [addButtonState, setButtonState] = useState(false);
  const [coProducers, setCoProducers] = useState([]);
  const clearData = () => {
    setButtonState(false);
    setCompanyDataError(false);
    setSelectedData({});
  };
  useEffect(() => {
    api.get('countries').then((response) => {
      const { results } = response;
      const filteredCountries = results.filter((item) => item.id !== 1);
      setCoProducers(filteredCountries);
    });
  }, []);
  return (
    <Box mt={2}>
      {!addButtonState && (
        <ButtonCustom
          onClick={() => {
            setButtonState(true);
          }}
        >
          + Add new country
        </ButtonCustom>
      )}
      {addButtonState && (
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonCustom onClick={() => {
            const data = {
              country_id: selectedData.company.id,
            };
            api.attachCountryProject(data,
              projectId, 'attach').then(() => {
              clearData();
              onChange();
            });
          }}
          >
            Add
          </ButtonCustom>
          <ButtonCustom
            style={{ width: 200 }}
            type="primary"
            onClick={() => {
              clearData();
            }}
          >
            Cancel
          </ButtonCustom>
          {isCostReport
            ? (
              <Box style={{ width: 200 }}>
                <Input
                  value={selectedData.company}
                  label="Companies"
                  error={companyDataError}
                  onChange={({ target: { value } }) => {
                    setSelectedData({ ...selectedData, company: value });
                  }}
                />
              </Box>
            )
            : (
              <Autocomplete
                onChange={(_, value) => {
                  setSelectedData({ ...selectedData, company: value });
                }}
                size="small"
                value={selectedData.company}
                options={coProducers}
                getOptionLabel={(option) => option.name}
                style={{ width: 400 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={companyDataError}
                    label="Country"
                    variant="outlined"
                  />
                )}
              />
            )}
        </Box>
      )}
    </Box>
  );
};

AddNewCountry.propTypes = {
  onChange: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      region_id: PropTypes.number,
      is_active: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
      vat_number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  countryId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

export default AddNewCountry;
