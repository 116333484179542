import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import ButtonCustom from '../../../../components/Button';
import api from '../../../../instruments/api';
import Input from '../../../../components/Input';
import SetSwitcher from '../SetSwitcher';

const SetFinance = (props) => {
  const {
    onChange, companies, match, country_id,
  } = props;
  const [selectedData, setSelectedData] = useState({});
  const [amountDataError, setAmountDataError] = useState(false);
  const [companyDataError, setCompanyDataError] = useState(false);
  const [detailsDataError, setDetailsDataError] = useState(false);
  const [addButtonState, setButtonState] = useState(false);
  const clearData = () => {
    setButtonState(false);
    setDetailsDataError(false);
    setCompanyDataError(false);
    setAmountDataError(false);
    setSelectedData({});
  };
  return (
    <Box mt={2}>
      {!addButtonState && (
      <ButtonCustom
        onClick={() => {
					  setButtonState(true);
        }}
      >
        + Add new Line
      </ButtonCustom>
      )}
      {addButtonState && (
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ButtonCustom onClick={() => {
					  let error = false;
					  if (!selectedData.amount) {
					    error = true;
					    setAmountDataError(true);
					  } else {
					    setAmountDataError(false);
					  }
					  if (!selectedData.details) {
					    error = true;
					    setDetailsDataError(true);
					  } else {
					    setDetailsDataError(false);
					  }
					  // eslint-disable-next-line no-prototype-builtins
					  if (!selectedData.hasOwnProperty('status')) {
					    selectedData.status = false;
					  }
					  if (!selectedData.company || !Object.keys(selectedData.company).length) {
					    error = true;
					    setCompanyDataError(true);
					  } else {
					    setCompanyDataError(false);
					  }
					  if (error) {
					    return;
					  }
					  const data = (country_id === 1) ? {
					    country_id,
            company_id: selectedData.company.id,
					    detail: selectedData.details,
					    is_confirmed: selectedData.status,
					    amount: parseInt(selectedData.amount, 10)
					  } : {
            country_id,
            company: selectedData.company.name,
            detail: selectedData.details,
            is_confirmed: selectedData.status,
            amount: parseInt(selectedData.amount, 10)
          };

					  api.post('projectsFinancing', data,
							 match.params.id).then(() => {
					    clearData();
					    onChange();
					  });
        }}
        >
          Add
        </ButtonCustom>
        <ButtonCustom
          type="primary"
          onClick={() => {
						  clearData();
          }}
        >
          Cancel
        </ButtonCustom>
        <Autocomplete
          onChange={(_, value) => {
            setSelectedData({ ...selectedData, company: value });
          }}
          size="small"
          value={selectedData.company}
          options={companies}
          getOptionLabel={(option) => option.name}
          style={{ width: 200 }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={companyDataError}
              label="Companies"
              variant="outlined"
            />
          )}
        />
        <Box style={{ width: 200 }}>
          <Input
            value={selectedData.details}
            label="Detail"
            error={detailsDataError}
            onChange={({ target: { value } }) => {
							  setSelectedData({ ...selectedData, details: value });
            }}
          />
        </Box>
        <Box style={{ width: 100 }}>
          <Input
            value={selectedData.amount}
            label="Amount"
            error={amountDataError}
            onChange={({ target: { value } }) => {
							  if (/^[0-9]*\.?[0-9]*$/.test(value)) {
							    setSelectedData({ ...selectedData, amount: value });
							  }
            }}
          />
        </Box>
        <SetSwitcher
          rowData={{ is_confirmed: false }}
          onChange={() => {
						  setSelectedData({ ...selectedData, status: !selectedData.status || false });
          }}
        />
      </Box>
      )}
    </Box>
  );
};

SetFinance.propTypes = {
  onChange: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      region_id: PropTypes.number,
      is_active: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
      vat_number: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  country_id: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

export default SetFinance;
