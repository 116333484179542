import React from 'react';
import CompaniesOverview from './CompaniesOverview';
import CompaniesCreatePopup from './CompaniesCreatePopup';

const Companies = () => {
  return (
    <>
      <CompaniesOverview />
      <CompaniesCreatePopup />
    </>
  );
};

export default Companies;
