import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import MaterialTable, {MTableToolbar} from 'material-table';
import PropTypes from 'prop-types';
import SetSwitcher from '../SetSwitcher';
import SetCountry from '../SetCompany';
import {useRowStyles} from '../../../Projects/ProjectsOverview/style';
import api from '../../../../instruments/api';

function FinanceTable(props) {
    const classes = useRowStyles();
    const {
        // eslint-disable-next-line camelcase
        companies, data, projectId, onChange, is_cost_report,
    } = props;
    const [dataSet, setData] = useState([]);
    useEffect(() => {
        setData(data.map((item) => ({...item, is_cost_report})));
    }, [data]);
    const columns = [
        {
            title: 'Funding',
            field: 'company_str',
            width: 150,
            editComponent: (props) => {
                return (
                    <SetCountry companies={companies} is_cost_report={is_cost_report} {...props} />
                );
            },
            render: (props) => {
                const {company, company_str} = props;
                return (
                    <Typography>
                        {`${company ? company.name : company_str}`}
                    </Typography>
                );
            }
        },
        {
            title: 'Detail',
            field: 'detail',
        },
        {
            title: 'Amount',
            field: 'amount',
            width: 5,
            render: (props) => {
                const {amount} = props;
                return (
                    <Typography>{`€${amount.toFixed(2)}`}</Typography>
                );
            }
        }, {
            title: 'Percentage country',
            field: 'percentage_country',
            width: 5,
            editComponent: () => (
                <>
                </>
            ),
            render: (props) => {
                const {percentage_country} = props;
                return (
                    <Tooltip
                        title={`${percentage_country}%`}
                        aria-label="add"
                    >
                        <Typography>
                            {percentage_country.toFixed(2)}%
                        </Typography>
                    </Tooltip>
                );
            }
        }, {
            title: 'Percentage total',
            field: 'percentage_total',
            width: 5,
            editComponent: () => (
                <>
                </>
            ),
            render: (props) => {
                const {percentage_total} = props;
                return (
                    <Tooltip
                        title={`${percentage_total}%`}
                        aria-label="add"
                    >
                        <Typography>
                            {percentage_total.toFixed(2)}%
                        </Typography>
                    </Tooltip>
                );
            }
        },
        {
            title: 'Confirmation status',
            field: 'is_confirmed',
            width: 5,
            render: (props) => {
                const {is_confirmed} = props;
                return (
                    <Tooltip
                        title="Confirmation status"
                        aria-label="add"
                    >
                        <Switch
                            disabled
                            checked={is_confirmed}
                            classes={{
                                root: classes.root,
                                switchBase: classes.switchBase,
                                thumb: classes.thumb,
                                track: classes.track,
                                checked: classes.checked,
                            }}
                            inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                    </Tooltip>
                );
            },
            editComponent: (props) => (
                <SetSwitcher {...props} />
            ),
        },
    ];
    return (
        <MaterialTable
            components={{
                Toolbar: (props) => (
                    <div style={{padding: 0, display: 'none'}}>
                        <MTableToolbar {...props} />
                    </div>
                )
            }}
            title=" "
            options={{
                search: false,
                paging: false,
                actionsColumnIndex: -1,
            }}
            columns={columns}
            data={dataSet}
            editable={{
                // isEditHidden: (rowData) => !rowData.Code.parent_id,
                // isDeleteHidden: (rowData) => !rowData.Code.parent_id,
                onRowUpdate: (newData) => new Promise((resolve) => {
                    console.log(newData);
                    const body = newData.countryId == 1 ? {
                        country_id: newData.countryId,
                        detail: newData.detail,
                        amount: newData.amount,
                        is_confirmed: newData.is_confirmed,
                    } : {
                        country_id: newData.countryId,
                        company_id: null,
                        company: newData.company.name,
                        detail: newData.detail,
                        amount: newData.amount,
                        is_confirmed: newData.is_confirmed,
                    };
                    if (newData.countryId == 1) {
                        if (is_cost_report) {
                            body.company = newData.company || newData.company_str;
                        } else {
                            body.company_id = newData.company.id;
                        }
                    }
                    
                    api.putWidthTwoParams('projectsFinancing',
                        body, projectId, newData.id).then(() => {
                        onChange();
                        resolve();
                    });
                }),
                onRowDelete: (data) => new Promise((resolve) => {
                    api.deleteWidthTwoParams('projectsFinancing', projectId, data.id).then(() => {
                        onChange();
                        resolve();
                    });
                }),
            }}
        />
    );
}

FinanceTable.defaultProps = {
    percentage_total: '',
    percentage_country: '',
    is_confirmed: false,
    company: {
        address: '',
        id: 0,
        is_active: false,
        name: '',
        region_id: 0,
        type: '',
        vat_number: '',
    },
    amount: 0,
};

FinanceTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            amount: PropTypes.string,
            countryId: PropTypes.number,
            detail: PropTypes.string,
            id: PropTypes.number,
            is_confirmed: PropTypes.bool,
            percentage_country: PropTypes.string,
            percentage_total: PropTypes.string,
            project_id: PropTypes.number,
        })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    percentage_total: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    percentage_country: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    is_confirmed: PropTypes.bool,
    company: PropTypes.shape({
        address: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        is_active: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        region_id: PropTypes.number,
        type: PropTypes.string.isRequired,
        vat_number: PropTypes.string.isRequired,
    }),
    companies: PropTypes.arrayOf(
        PropTypes.shape({
            address: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            region_id: PropTypes.number,
            is_active: PropTypes.bool.isRequired,
            type: PropTypes.string.isRequired,
            vat_number: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    is_cost_report: PropTypes.bool.isRequired,
    projectId: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    amount: PropTypes.number,
};
export default FinanceTable;
