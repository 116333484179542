import { makeStyles } from '@material-ui/core/styles';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  switchBase: {
    color: '#7d7d7d',
    '&$checked': {
      color: '#26c952',
    },
    '&$checked + $track': {
      backgroundColor: '#26c952',
    },
  },
  checked: {},
  track: {},
  row: {
    '&:last-child': {
      border: 'none',
      '& td': {
        border: 'none',
      }
    }
  }
});
export default useRowStyles;
