import React from 'react';
import CodesCreator from './CodesCreator';
import CodesOverview from './CodesOverview';

const Codes = () => {
  return (
    <>
      <CodesCreator />
      <CodesOverview />
    </>
  );
};
export default Codes;
